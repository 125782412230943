import styled from 'styled-components';

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 10px;
`;

export const StyledTitle = styled.div`
  font-family: "Apercu Pro";
  font-size: 16px;
  font-weight: 1000;
  display: flex;

  p {
    width: max-content !important;
  }

  div {
    width: max-content !important;
  }
`;

export const StyledTimezoneWrapper = styled.div`
  display: flex;
  gap: 20px;
`;

export const StyledTimezone = styled.div`
  font-family: "Apercu Pro";
`;

export const StyledTimezoneChange = styled.button`
  font-family: 'Futura Extra Black Condensed';
  font-size: 15px;
  color: #6f6cc9;
`;