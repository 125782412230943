import styled, { css } from 'styled-components';

export const StyledContentWrapper = styled.button<{ fullWidth?: boolean }>`
  position: relative;
  padding: 0.5em 2em;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  
  ${({ fullWidth }) => fullWidth && css`
    width: 100%;
  `}
`;

export const StyledStartIconWrapper = styled.div`
  margin: auto 0.5em auto 0;
`;

export const StyledTextWrapper = styled.div<{ isLoading?: boolean }>`
  visibility: ${({ isLoading }) => !isLoading ? 'initial' : 'hidden'};
`;

export const StyledLoadingWrapper = styled.div<{ isLoading?: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  justify-content: center;
  align-items: center;
  color: inherit;
  display: ${({ isLoading }) => isLoading ? 'flex' : 'none'};
`;