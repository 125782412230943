import { Dialog } from '@mui/material';
import { StyledWrapper } from './Modal.styled';
import { CSSProperties } from 'styled-components';
import { ReactNode, useEffect } from 'react';

type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
  paperStyle?: CSSProperties;
  style?: CSSProperties;
  wrapperStyle?: CSSProperties;
  children: ReactNode;
  centered?: boolean;
  isFullScreen?: boolean;
};

export const Modal = (props: ModalProps) => {

  useEffect(() => {
    const htmlElement = document.documentElement;

    if (props.isOpen) {
      htmlElement.style.overflow = 'hidden';
    } else {
      htmlElement.style.overflow = '';
    }
    return () => {
      htmlElement.style.overflow = '';
    };
  }, [props.isOpen]);


  return (
    <Dialog
      {...props}
      maxWidth={false}
      open={props.isOpen}
      onClose={props.onClose}
      disableEnforceFocus
      fullScreen={props.isFullScreen}
      PaperProps={{
        sx: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
          width: props.isFullScreen ? '100dvw' : 'auto',
          bottom: 'auto',
          position: 'relative',
          margin: '0',
          borderRadius: props.isFullScreen ? 0 : 8,
          maxWidth: '100dvw',
          ...props?.paperStyle,
        },
      }}
      style={{
        zIndex: 100,
        backdropFilter: 'blur(3px)',
        backgroundColor: 'transparent',
        ...props.style,
      }}
    >
      <StyledWrapper style={{ ...props?.wrapperStyle }}>
        {props.children}
      </StyledWrapper>
    </Dialog>
  );
};
