import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { useRouter } from '../../../../../lib/hooks/use-router';
import {
  StyledArrowButton,
  StyledArrows,
  StyledCalendarDay,
  StyledCalendarDayButton,
  StyledDaysWrapper,
  StyledHeader,
  StyledMonthSelector,
  StyledWeekday,
  StyledWeekdays,
  StyledWrapper
} from './ConsultationsPlanningCalendar.styled';
import { dayjsWithOffset } from '../../../../../lib/helpers/dayjs-with-offest';

dayjs.extend(weekday);
dayjs.extend(weekOfYear);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

type CalendarProps = {
  selectedDay: dayjs.Dayjs;
  onSelectDay: (day: dayjs.Dayjs) => void;
}

export const ConsultationsPlanningCalendar = ({ onSelectDay, selectedDay }: CalendarProps) => {
  const { locale } = useRouter();
  const [currentMonth, setCurrentMonth] = useState(dayjsWithOffset());

  const monthsLimit = 6;
  const startMonth = dayjsWithOffset().startOf('month');
  const endMonth = startMonth.add(monthsLimit, 'month');

  const handleMonthChange = (direction: 'next' | 'prev') => {
    if (direction === 'next' && currentMonth.isBefore(endMonth, 'month')) {
      setCurrentMonth(currentMonth.add(1, 'month'));
    } else if (direction === 'prev' && currentMonth.isAfter(startMonth, 'month')) {
      setCurrentMonth(currentMonth.subtract(1, 'month'));
    }
  };

  useEffect(() => {
    setCurrentMonth(selectedDay);
  }, [selectedDay]);

  const handleDaySelect = (day: dayjs.Dayjs) => {
    onSelectDay(day);
  };

  const isDaySelected = (day: dayjs.Dayjs) => {
    return selectedDay.locale(locale).startOf('week').isSame(day.locale(locale).startOf('week'), 'week');
  };

  const renderCalendar = () => {
    const daysInMonth = currentMonth.daysInMonth();
    const firstDayOfMonth = currentMonth.startOf('month').locale(locale).weekday();
    const days = [];

    const generateDay = (day: number, date: dayjs.Dayjs, isAnotherMonth = false) => (
      <StyledCalendarDay
        key={`${isAnotherMonth ? 'other' : 'current'}-${day}`}
        isFirst={dayjsWithOffset(date).weekday() === 0}
        isLast={dayjsWithOffset(date).weekday() === 6}
        selected={isDaySelected(date)}
        allowedToSelect={dayjsWithOffset(date).isSameOrAfter(dayjsWithOffset().startOf('day'))}
      >
        <StyledCalendarDayButton
          selected={isDaySelected(date)}
          isAnotherMonth={isAnotherMonth}
          onClick={() => handleDaySelect(date)}
          allowedToSelect={dayjsWithOffset(date).isSameOrAfter(dayjsWithOffset().startOf('day'))}
        >
          {day}
        </StyledCalendarDayButton>
      </StyledCalendarDay>
    );

    const prevMonth = currentMonth.clone().subtract(1, 'month');
    const prevMonthDays = prevMonth.daysInMonth();
    for (let i = 1; i <= firstDayOfMonth; i++) {
      const day = prevMonthDays - firstDayOfMonth + i;
      const date = prevMonth.date(day);
      days.push(generateDay(day, date, true));
    }

    for (let day = 1; day <= daysInMonth; day++) {
      const date = currentMonth.date(day);
      days.push(generateDay(day, date));
    }

    const totalDays = days.length;
    const remainingDays = (7 - (totalDays % 7)) % 7;
    const nextMonth = currentMonth.clone().add(1, 'month');
    for (let day = 1; day <= remainingDays; day++) {
      const date = nextMonth.date(day);
      days.push(generateDay(day, date, true));
    }

    return days;
  };

  return (
    <StyledWrapper>
      <StyledHeader>
        <StyledMonthSelector>
          {currentMonth.format('MMMM')}&nbsp;<span style={{ color: '#e31337' }}>{currentMonth.format('YYYY')}</span>
        </StyledMonthSelector>
        <StyledArrows>
          <StyledArrowButton
            onClick={() => handleMonthChange('prev')}
            disabled={currentMonth.isSameOrBefore(startMonth, 'month')}
          >
            <svg width="14" height="22" viewBox="0 0 14 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11 19L3 11L11 3" stroke="#B3B3B3" strokeWidth="5" strokeLinecap="round"
                    strokeLinejoin="round"/>
            </svg>
          </StyledArrowButton>
          <StyledArrowButton
            onClick={() => handleMonthChange('next')}
            disabled={currentMonth.isSameOrAfter(endMonth, 'month')}
          >
            <svg width="13" height="22" viewBox="0 0 13 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M3 3L10.5 11L3 19" stroke="#B3B3B3" strokeWidth="5" strokeLinecap="round"
                    strokeLinejoin="round"/>
            </svg>
          </StyledArrowButton>
        </StyledArrows>
      </StyledHeader>
      <StyledWeekdays>
        {Array.from({ length: 7 }).map((_, index) => (
          <StyledWeekday key={index}>{dayjsWithOffset().locale(locale).weekday(index).format('ddd')}</StyledWeekday>
        ))}
      </StyledWeekdays>
      <StyledDaysWrapper>{renderCalendar()}</StyledDaysWrapper>
    </StyledWrapper>
  );
};