export enum TranslationTypesEnum {
  Main = 'main',
  Homepage = 'homepage',
  WhoWeHelp = 'who-we-help',
  WhyShoelessly = 'why-shoelessly',
  SymptomAssessment = 'symptom-assessment',
  SecondOpinion = 'second-opinion',
  HealthGuidance = 'health-guidance',
  SupportDiscussion = 'support-discussion',
  Article = 'article',
  Account = 'account',
  MedicalDictionary = 'medical-dictionary',
  CaseStories = 'case-stories',
  DiseasesAndConditions = 'diseases-and-conditions',
  Symptoms = 'symptoms',
  ExaminationsAndProcedures = 'examinations-and-procedures',
  WellBeing = 'well-being',
  CategoryResults = 'category-results',
  SearchResults = 'search-results',
  HealthLibrary = 'health-library',
  ResetPassword = 'reset-password',
  AuthorArticles = 'author-articles',
  FindYourSpecialist = 'find-your-specialist',
  BookAnAppointment = 'book-an-appointment',
  PromoWelcome = 'promo-welcome',
}

export enum TranslationLocalesEnum {
  EN = 'en',
  SK = 'sk',
  DE = 'de',
}

export type TranslationProps = {
  key: string;
  type: TranslationTypesEnum;
};