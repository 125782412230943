import styled from 'styled-components';

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const StyledContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const StyledTitle = styled.div`
  font-family: Futura Extra Black Condensed;
  font-size: 22px;
`;

export const StyledInnerContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const StyledText = styled.div`
  font-family: Apercu Pro;
`;

export const StyledTextBold = styled.div`
  font-family: Apercu Pro;
  font-weight: bold;
`;

export const StyledCheckbox = styled.button`
  display: flex;
`;

export const StyledCheckboxUnchecked = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 8px;
  border: 3px solid #E31337;
  background-color: white;
`;