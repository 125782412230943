import { initializeApp } from 'firebase/app';
import {
  browserLocalPersistence,
  FacebookAuthProvider,
  getAuth,
  GoogleAuthProvider,
  OAuthProvider,
  setPersistence,
} from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions';

const firebaseConfig = {
  apiKey: 'AIzaSyCOY0EHL3go-JT3oyWfRb5U5wXU9gNvrzA',
  authDomain: 'shoeley-c7e1f.firebaseapp.com',
  databaseURL: 'https://shoeley-c7e1f-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'shoeley-c7e1f',
  storageBucket: 'shoeley-c7e1f.appspot.com',
  messagingSenderId: '724401449303',
  appId: '1:724401449303:web:729b7210365cedc447b612',
  measurementId: 'G-XQNDRHWPSZ',
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const functions = getFunctions(app, 'europe-west1');

if (process.env.NODE_ENV === 'development') {
  console.info('Connecting to Emulator');
  connectFunctionsEmulator(functions, 'localhost', 5001);
}

export const googleProvider = new GoogleAuthProvider();
export const facebookProvider = new FacebookAuthProvider();
const appleProvider = new OAuthProvider('apple.com')
appleProvider.addScope('email');
appleProvider.addScope('name');
export { appleProvider };

setPersistence(auth, browserLocalPersistence).catch((e) => console.error(e));