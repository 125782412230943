import styled, { css } from 'styled-components';
import { Button } from '../../../UI/Button/Button';
import { desktopMedia } from '@vaza-eu/vaza/dist/styles/devices';

export const StyledWrapper = styled(Button)<{ type: 'big' | 'small' }>`
  background-color: white;
  border-radius: 16px;
  width: ${({ type }) => type === 'big' ? 100 : 30}%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 4px 8px 24px 0 rgba(36, 107, 253, 20%);

  ${({ type }) => type === 'small' && css`
    #button-icon {
      margin: 0;
    }
  `};

  @media (${desktopMedia}) {
    width: ${({ type }) => type === 'big' ? 378 : 88}px;
  }
`;

export const StyledText = styled.div`
  font-family: Futura Extra Black Condensed;
  font-size: 18px;
  font-weight: 900;
  padding-left: 5px;
`;