import { StyledWrapper } from './ConsultationReports.styled';
import { NoResults } from '../../../components/NoResults/NoResults';

export const ConsultationReports = () => {

  return (
    <StyledWrapper>
      <NoResults id={'appointment-reports'}/>
    </StyledWrapper>
  );
};