import { useMutation, UseMutationResult } from 'react-query';
import { functions } from '../../../firebase';
import { httpsCallable } from 'firebase/functions';
import { SignInUserWithProviderRequest, SignInUserWithProviderResponse } from '@vaza-eu/shoelessly';

export const useSignInUserWithProviderMutation = (): UseMutationResult<SignInUserWithProviderResponse, unknown, SignInUserWithProviderRequest> =>
  useMutation<SignInUserWithProviderResponse, unknown, SignInUserWithProviderRequest>(async (data: SignInUserWithProviderRequest) => {
    const cloudFn = httpsCallable<SignInUserWithProviderRequest, SignInUserWithProviderResponse>(functions,
      'signInUserWithProvider');
    const response = await cloudFn(data);
    return response.data;
  });