import {
  StyledFavoriteWrapper,
  StyledImageWrapper,
  StyledSelectedTimeInfo,
  StyledSelectedTimeInfoConfirmation,
  StyledSelectedTimeInfoText,
  StyledSelectedTimeInfoTextComponent,
  StyledSpecialization,
  StyledSpecializationName,
  StyledTitleText,
  StyledTitleWrapper,
  StyledWrapper
} from './BookAnAppointmentDoctorCard.styled';
import Image from 'next/image';
import React from 'react';
import { Content } from '../../Content/Content';
import dayjs from 'dayjs';
import { useRouter } from '../../../lib/hooks/use-router';

type BookAnAppointmentDoctorCardProps = {
  selectedTime: dayjs.Dayjs | null;
}

export const BookAnAppointmentDoctorCard = ({ selectedTime }: BookAnAppointmentDoctorCardProps) => {
  const { locale } = useRouter();

  return (
    <StyledWrapper>
      <StyledImageWrapper>
        <Image src={'/images/article-photo-placeholder.png'} alt={''} fill/>
      </StyledImageWrapper>
      <StyledTitleWrapper>
        <StyledTitleText>
          Dr. IVANA VYRVOVA
        </StyledTitleText>
        <StyledFavoriteWrapper>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M11.7097 0.582164C12.2355 0.582164 12.7605 0.656331 13.2596 0.823831C16.3355 1.82383 17.4438 5.19883 16.518 8.14883C15.993 9.65633 15.1347 11.0322 14.0105 12.1563C12.4013 13.7147 10.6355 15.098 8.73465 16.2897L8.52632 16.4155L8.30965 16.2813C6.40215 15.098 4.62632 13.7147 3.00215 12.148C1.88548 11.0238 1.02632 9.65633 0.492983 8.14883C-0.448684 5.19883 0.659649 1.82383 3.76882 0.806331C4.01048 0.722998 4.25965 0.664664 4.50965 0.632164H4.60965C4.84382 0.597998 5.07632 0.582164 5.30965 0.582164H5.40132C5.92632 0.597998 6.43465 0.689664 6.92715 0.857164H6.97632C7.00965 0.872998 7.03465 0.890498 7.05132 0.906331C7.23548 0.965498 7.40965 1.03216 7.57632 1.12383L7.89298 1.2655C7.9695 1.30631 8.05539 1.36866 8.12961 1.42256C8.17665 1.4567 8.219 1.48745 8.25132 1.50716C8.26492 1.51519 8.27874 1.52326 8.29268 1.53139C8.36413 1.5731 8.43856 1.61655 8.50132 1.66466C9.42715 0.957164 10.5513 0.573831 11.7097 0.582164ZM13.9263 6.58216C14.268 6.573 14.5597 6.29883 14.5847 5.948V5.84883C14.6096 4.68133 13.9022 3.62383 12.8263 3.2155C12.4846 3.098 12.1096 3.28216 11.9846 3.63216C11.868 3.98216 12.0513 4.3655 12.4013 4.48966C12.9355 4.68966 13.293 5.2155 13.293 5.798V5.82383C13.2772 6.01466 13.3347 6.19883 13.4513 6.3405C13.568 6.48216 13.743 6.56466 13.9263 6.58216Z"
              fill="#E31337"
            />
          </svg>
        </StyledFavoriteWrapper>
      </StyledTitleWrapper>
      <StyledSpecialization>
        <Content id={'bookAnAppointment.doctorCard.specialization'}/>
        <StyledSpecializationName>Otorinolaryngologist</StyledSpecializationName>
      </StyledSpecialization>
      {selectedTime && (
        <StyledSelectedTimeInfo>
          <StyledSelectedTimeInfoText>
            <Content id={'bookAnAppointment.doctorCard.selectedTime'}/>
            <StyledSelectedTimeInfoTextComponent>
              {selectedTime.format('MMMM DD, YYYY')}
            </StyledSelectedTimeInfoTextComponent>
            <Content id={'bookAnAppointment.doctorCard.selectedTime2'}/>
            <StyledSelectedTimeInfoTextComponent>
              {selectedTime.format('HH:mm A')}
            </StyledSelectedTimeInfoTextComponent>
            <StyledSelectedTimeInfoTextComponent>
              <Content id={'bookAnAppointment.doctorCard.selectedTime3'}/>
            </StyledSelectedTimeInfoTextComponent>
            <StyledSelectedTimeInfoTextComponent style={{ color: 'black', padding: '0 0 0 4px' }}>
              DR. IVANA VYRVOVA
            </StyledSelectedTimeInfoTextComponent>
            .
          </StyledSelectedTimeInfoText>
          <StyledSelectedTimeInfoConfirmation>
            <Content id={'bookAnAppointment.doctorCard.confirmation'}/>
          </StyledSelectedTimeInfoConfirmation>
        </StyledSelectedTimeInfo>
      )}
    </StyledWrapper>
  );
};