import {
  StyledArrowButton,
  StyledArrows,
  StyledTimetableWrapper,
  StyledTitle,
  StyledTopWrapper,
  StyledWrapper
} from './AvailabilityTimeSlotsTable.styled';
import { Content } from '../../../../../components/Content/Content';
import React from 'react';
import dayjs from 'dayjs';
import { AvailabilityTimeSlotsTableItem } from './AvailabilityTimeSlotsTableItem/AvailabilityTimeSlotsTableItem';
import { useGetDoctorAvailabilityTimeSlotsQuery } from '../../../../../services/consultations/common';
import { getAuth } from 'firebase/auth';
import { AVAILABLE_FUTURE_DAYS } from '../ConsultationsPlaning';
import { useRouter } from '../../../../../lib/hooks/use-router';
import { dayjsWithOffset } from '../../../../../lib/helpers/dayjs-with-offest';

type AvailabilityTimeTableProps = {
  selectedDay: dayjs.Dayjs;
  onSelectDay: (day: dayjs.Dayjs) => void;
};

export const AvailabilityTimeSlotsTable = ({ selectedDay, onSelectDay }: AvailabilityTimeTableProps) => {
  const { locale } = useRouter();

  const auth = getAuth();
  const { data: timeSlots } = useGetDoctorAvailabilityTimeSlotsQuery({ doctorId: auth.currentUser?.uid ?? '' });

  const thisWeek = timeSlots?.filter((dayAvailability) => {
    const day = dayjsWithOffset(dayAvailability.day);
    const startOfWeek = selectedDay.locale(locale).startOf('week');
    const endOfWeek = startOfWeek.add(6, 'day');

    return day.isSameOrAfter(startOfWeek, 'day') && day.isSameOrBefore(endOfWeek, 'day');
  });

  const handleWeekChange = (direction: 'next' | 'prev') => {
    onSelectDay(selectedDay.add(direction === 'next' ? 1 : -1, 'week'));
  };

  return (
    <StyledWrapper>
      <StyledTopWrapper>
        <StyledTitle>
          <Content id={'consultations.planing.timeslots.table'}/>
        </StyledTitle>
        <StyledArrows>
          <StyledArrowButton
            onClick={() => handleWeekChange('prev')}
            disabled={dayjsWithOffset(selectedDay).isSameOrBefore(dayjsWithOffset(), 'day')}
          >
            <svg width="14" height="22" viewBox="0 0 14 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11 19L3 11L11 3" stroke="#B3B3B3" strokeWidth="5" strokeLinecap="round"
                    strokeLinejoin="round"/>
            </svg>
          </StyledArrowButton>
          <StyledArrowButton
            onClick={() => handleWeekChange('next')}
            disabled={dayjsWithOffset(selectedDay).isSameOrAfter(dayjsWithOffset().add(AVAILABLE_FUTURE_DAYS - 7,
              'day'), 'day')}
          >
            <svg width="13" height="22" viewBox="0 0 13 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M3 3L10.5 11L3 19" stroke="#B3B3B3" strokeWidth="5" strokeLinecap="round"
                    strokeLinejoin="round"/>
            </svg>
          </StyledArrowButton>
        </StyledArrows>
      </StyledTopWrapper>
      <StyledTimetableWrapper>
        {thisWeek?.map((dayAvailability) => (
          <AvailabilityTimeSlotsTableItem
            key={dayjsWithOffset(dayAvailability.day).toISOString()}
            availability={dayAvailability}
            isActive={selectedDay.isSame(dayAvailability.day, 'day')}
          />
        ))}
      </StyledTimetableWrapper>
    </StyledWrapper>
  );
};