import { useQuery } from 'react-query';
import { db } from '../../../firebase';
import { doc, getDoc } from 'firebase/firestore';
import { TranslationProps } from '../../lib/types/translation-types-enum';

const locales = ['sk', 'de', 'en'];

export const fetchSingleTranslation = async (type: TranslationProps['type']): Promise<SingleTranslationResponse | null> => {
  try {
    const docRefs = locales.map(locale => getDoc(doc(db, 'translations', `${locale}-${type}`)));
    const docs = await Promise.all(docRefs);
    const translations = docs.map(doc => doc.data());

    return {
      sk: translations[0] ?? {},
      de: translations[1] ?? {},
      en: translations[2] ?? {},
      cs: translations[3] ?? {},
    };
  } catch (error) {
    console.error(error);
    return null;
  }
};

export type SingleTranslationResponse = {
  sk: {
    [key: string]: string;
  };
  de: {
    [key: string]: string;
  };
  en: {
    [key: string]: string;
  };
  cs: {
    [key: string]: string;
  };
}

export const useAllLanguagesTranslationsForTypeQuery = (type: TranslationProps['type']) => {
  return useQuery<SingleTranslationResponse | null>([`translations`, type], () => fetchSingleTranslation(type), {
    enabled: !!type,
  });
};

