import {
  StyledBottom,
  StyledBottomImage,
  StyledBottomMainImage,
  StyledBottomSecondaryImage,
  StyledBottomText,
  StyledBottomTextBold,
  StyledDescription,
  StyledDivider,
  StyledDividerLine,
  StyledFieldsWrapper,
  StyledMethodsButtonsWrapper,
  StyledTitle,
  StyledVerificationCodeInput,
  StyledVerificationCodeInputsWrapper,
  StyledWrapper
} from './BookAnAppointmentAuth.styled';
import { Content } from '../../../Content/Content';
import { TranslationTypesEnum } from '../../../../lib/types/translation-types-enum';
import { AuthInput } from '../../../Layout/Auth/AuthInput/AuthInput';
import { AUTH_FORM_FIELDS, AUTH_VALIDATION } from '../../../Layout/Auth/Auth.constants';
import React, { ChangeEvent, RefObject, useEffect, useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useRouter } from '../../../../lib/hooks/use-router';
import { SingInMethodButton } from '../../../Layout/Auth/SingInMethodButton/SingInMethodButton';
import { BookAnAppointmentSteps } from '../BookAnAppointmentModal';
import Image from 'next/image';

type BookAnAppointmentAuthProps = {
  step: BookAnAppointmentSteps;
  setStep: (step: BookAnAppointmentSteps) => void;
};

export const BookAnAppointmentAuth = ({ step, setStep }: BookAnAppointmentAuthProps) => {
  const { locale } = useRouter();

  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      email: '',
      firstName: '',
      lastName: '',
      nickname: '',
      timezone: '',
    },
    resolver: yupResolver(AUTH_VALIDATION),
    shouldFocusError: true,
  });

  const isCreateAccount = step === BookAnAppointmentSteps.AUTH_INITIAL || step === BookAnAppointmentSteps.AUTH_FILL_INFO;
  const isVerifyCode = step === BookAnAppointmentSteps.AUTH_CONFIRMATION_CODE;
  const isLogin = step === BookAnAppointmentSteps.AUTH_LOGIN;

  return (
    <FormProvider {...form}>
      <StyledWrapper>
        {isCreateAccount && <CreateAnAccount {...{ step, setStep }}/>}
        {isVerifyCode && <VerifyConfirmationCode {...{ step, setStep }}/>}
        {isLogin && <Login {...{ step, setStep }}/>}
      </StyledWrapper>
    </FormProvider>
  );
};

const CreateAnAccount = ({ step, setStep }: BookAnAppointmentAuthProps) => {

  const handleSignUp = () => {
    setStep(BookAnAppointmentSteps.AUTH_LOGIN);
  };

  return (
    <StyledWrapper>
      <StyledTitle>
        <Content id={'modal.create-an-account.title'} type={TranslationTypesEnum.BookAnAppointment}/>
      </StyledTitle>
      <StyledDescription>
        <Content id={'modal.create-an-account.description'} type={TranslationTypesEnum.BookAnAppointment}/>
      </StyledDescription>
      <StyledFieldsWrapper>
        {step === BookAnAppointmentSteps.AUTH_INITIAL && (
          <>
            <AuthInput fieldName={AUTH_FORM_FIELDS.email}/>
            <AuthInput fieldName={AUTH_FORM_FIELDS.password}/>
            <AuthInput fieldName={AUTH_FORM_FIELDS.passwordConfirmation}/>
          </>
        )}
        {step === BookAnAppointmentSteps.AUTH_FILL_INFO && (
          <>
            <AuthInput fieldName={AUTH_FORM_FIELDS.firstName}/>
            <AuthInput fieldName={AUTH_FORM_FIELDS.lastName}/>
            <AuthInput fieldName={AUTH_FORM_FIELDS.nickname}/>
            <AuthInput fieldName={AUTH_FORM_FIELDS.timezone}/>
          </>
        )}
      </StyledFieldsWrapper>
      <StyledDivider>
        <StyledDividerLine/>
        <Content id={'login.orContinueWith'} type={TranslationTypesEnum.BookAnAppointment}/>
        <StyledDividerLine/>
      </StyledDivider>
      <StyledMethodsButtonsWrapper>
        <SingInMethodButton type={'small'} method={'facebook'}/>
        <SingInMethodButton type={'small'} method={'google'}/>
        <SingInMethodButton type={'small'} method={'apple'}/>
      </StyledMethodsButtonsWrapper>
      <StyledBottom onClick={handleSignUp}>
        <StyledBottomText>
          <Content id={'login.alreadyHaveAnAccount'} type={TranslationTypesEnum.BookAnAppointment}/>
        </StyledBottomText>
        <StyledBottomTextBold>
          <Content id={'login.alreadyHaveAnAccountBold'} type={TranslationTypesEnum.BookAnAppointment}/>
        </StyledBottomTextBold>
      </StyledBottom>
    </StyledWrapper>
  );
};

const Login = ({ step, setStep }: BookAnAppointmentAuthProps) => {

  const handleRegister = () => {
    setStep(BookAnAppointmentSteps.AUTH_INITIAL);
  };

  return (
    <StyledWrapper>
      <StyledTitle>
        <Content id={'modal.login.title'} type={TranslationTypesEnum.BookAnAppointment}/>
      </StyledTitle>
      <StyledDescription>
        <Content id={'modal.login.description'} type={TranslationTypesEnum.BookAnAppointment}/>
      </StyledDescription>
      <StyledFieldsWrapper>
        <AuthInput fieldName={AUTH_FORM_FIELDS.email}/>
        <AuthInput fieldName={AUTH_FORM_FIELDS.password}/>
      </StyledFieldsWrapper>
      <StyledDivider>
        <StyledDividerLine/>
        <Content id={'login.orContinueWith'} type={TranslationTypesEnum.BookAnAppointment}/>
        <StyledDividerLine/>
      </StyledDivider>
      <StyledMethodsButtonsWrapper>
        <SingInMethodButton type={'small'} method={'facebook'}/>
        <SingInMethodButton type={'small'} method={'google'}/>
        <SingInMethodButton type={'small'} method={'apple'}/>
      </StyledMethodsButtonsWrapper>
      <StyledBottom onClick={handleRegister}>
        <StyledBottomText>
          <Content id={'login.signInWithEmail'} type={TranslationTypesEnum.BookAnAppointment}/>
        </StyledBottomText>
        <StyledBottomTextBold>
          <Content id={'login.signInWithEmailBold'} type={TranslationTypesEnum.BookAnAppointment}/>
        </StyledBottomTextBold>
      </StyledBottom>
    </StyledWrapper>
  );
};

type VerifyConfirmationCodeProps = {
  step: BookAnAppointmentSteps;
  setStep: (step: BookAnAppointmentSteps) => void;
};

const VerifyConfirmationCode = ({ step, setStep }: VerifyConfirmationCodeProps) => {
  const inputRefs: RefObject<HTMLInputElement>[] = [useRef(null), useRef(null), useRef(null), useRef(null)];

  useEffect(() => {
    inputRefs[0].current?.focus();
  }, []);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>, index: number) => {
    const value = e.target.value;
    if (/^\d$/.test(value)) {
      e.target.value = value;
      if (index < inputRefs.length - 1) {
        inputRefs[index + 1].current?.focus();
      }
    } else {
      e.target.value = '';
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLDivElement>) => {
    const pastedData = e.clipboardData.getData('Text').slice(0, 4);
    const digits = pastedData.split('').filter((char) => /^\d$/.test(char));
    digits.forEach((digit, index) => {
      if (inputRefs[index] && inputRefs[index].current) {
        inputRefs[index].current.value = digit;
        if (index < inputRefs.length - 1) {
          inputRefs[index + 1].current?.focus();
        }
      }
    });
    e.preventDefault();
  };

  return (
    <StyledWrapper>
      <StyledTitle>
        <Content id={'modal.verify-confirmation-code.title'} type={TranslationTypesEnum.BookAnAppointment}/>
      </StyledTitle>
      <StyledDescription>
        <Content id={'modal.verify-confirmation-code.description'} type={TranslationTypesEnum.BookAnAppointment}/>
      </StyledDescription>
      <StyledVerificationCodeInputsWrapper onPaste={handlePaste}>
        {inputRefs.map((ref, index) => (
          <StyledVerificationCodeInput
            key={index}
            ref={ref}
            maxLength={1}
            onClick={() => ref.current?.focus()}
            onChange={(e) => handleInputChange(e, index)}
            type="text"
          />
        ))}
      </StyledVerificationCodeInputsWrapper>
      <StyledDescription style={{ margin: '20px 0 0 0' }}>
        <Content id={'modal.verify-confirmation-code.resendCodeDescription1'}
                 type={TranslationTypesEnum.BookAnAppointment}/>
        <Content id={'modal.verify-confirmation-code.resendCodeDescription2'}
                 type={TranslationTypesEnum.BookAnAppointment}/>
      </StyledDescription>
      <StyledDescription>
        <Content id={'modal.verify-confirmation-code.resendCode1'} type={TranslationTypesEnum.BookAnAppointment}/>
        <Content id={'modal.verify-confirmation-code.resendCode2'} type={TranslationTypesEnum.BookAnAppointment}/>
      </StyledDescription>
      <StyledBottomImage>
        <StyledBottomMainImage>
          <Image src={'/images/bird-in-chair.png'} alt={'Shoelessly'} fill/>
        </StyledBottomMainImage>
        <StyledBottomSecondaryImage>
          <Image src={'/images/phone.png'} alt={'Shoelessly'} fill/>
        </StyledBottomSecondaryImage>
      </StyledBottomImage>
    </StyledWrapper>
  );
};