import {
  StyledTimetableWrapper,
  StyledTitle,
  StyledTopPanel,
  StyledTopPanelCopyText,
  StyledTopPanelCopyWrapper,
  StyledWrapper
} from './AvailabilityTimeTable.styled';
import { Content } from '../../../../../components/Content/Content';
import React from 'react';
import { AvailabilityTimeTableItem } from './AvailabilityTimeTableItem/AvailabilityTimeTableItem';
import { AvailabilitiesState } from '../ConsultationsPlaning';
import dayjs from 'dayjs';
import { useRouter } from '../../../../../lib/hooks/use-router';
import { dayjsWithOffset } from '../../../../../lib/helpers/dayjs-with-offest';

type AvailabilityTimeTableProps = {
  selectedDay: dayjs.Dayjs;
  availability: AvailabilitiesState[];
  setAvailability: (availability: AvailabilitiesState[]) => void;
};

export const AvailabilityTimeTable = ({ availability, setAvailability, selectedDay }: AvailabilityTimeTableProps) => {
  const { locale } = useRouter();

  const handleUpdateDayConsultations = (day: AvailabilitiesState['day'],
    isAvailable: AvailabilitiesState['isAvailable'],
    availabilityTimes: AvailabilitiesState['availability']) => {
    const newAvailability = availability?.map((dayAvailability) => {
      if (dayAvailability.day.isSame(day, 'day')) {
        return {
          day: dayAvailability.day,
          isAvailable,
          availability: availabilityTimes,
        };
      }
      return dayAvailability;
    });

    setAvailability(newAvailability);
  };

  const thisWeek = availability?.filter((dayAvailability) => {
    const day = dayjsWithOffset(dayAvailability.day);
    const startOfWeek = selectedDay.locale(locale).startOf('week');
    const endOfWeek = startOfWeek.add(6, 'day');

    return day.isSameOrAfter(startOfWeek, 'day') && day.isSameOrBefore(endOfWeek, 'day');
  });

  const copyForWholeWeek = (day: AvailabilitiesState['day'], isAvailable: AvailabilitiesState['isAvailable']) => {
    const startOfWeek = day.startOf('week');
    const endOfWeek = day.endOf('week');

    const newAvailability = availability.map((dayAvailability) => {
      if (dayAvailability.day.isSameOrAfter(startOfWeek, 'day') && dayAvailability.day.isSameOrBefore(endOfWeek,
        'day')) {
        return {
          ...dayAvailability,
          isAvailable,
          availability: availability.find(d => d.day.isSame(day, 'day'))?.availability || [] // Copy time slots from selected day
        };
      }
      return dayAvailability;
    });

    setAvailability(newAvailability);
  };

  const handleWeekSettingsApplyForWholeMonth = () => {
    const startOfMonth = selectedDay.startOf('month');
    const endOfMonth = selectedDay.endOf('month');

    const startOfWeek = selectedDay.startOf('week');
    const endOfWeek = selectedDay.endOf('week');

    const currentWeekSettings = availability.filter((dayAvailability) =>
      dayAvailability.day.isSameOrAfter(startOfWeek, 'day') &&
      dayAvailability.day.isSameOrBefore(endOfWeek, 'day')
    );

    const newAvailability = availability.map((dayAvailability) => {
      if (dayAvailability.day.isSameOrAfter(startOfMonth, 'day') && dayAvailability.day.isSameOrBefore(endOfMonth,
        'day')) {
        const correspondingDay = currentWeekSettings.find((weekDay) =>
          weekDay.day.day() === dayAvailability.day.day()
        );

        if (correspondingDay) {
          return {
            ...dayAvailability,
            isAvailable: correspondingDay.isAvailable,
            availability: correspondingDay.availability
          };
        }
      }
      return dayAvailability;
    });

    setAvailability(newAvailability);
  };


  return (
    <StyledWrapper>
      <StyledTopPanel>
        <StyledTitle>
          <Content id={'consultations.planing.timetable.table'}/>
        </StyledTitle>
        <StyledTopPanelCopyWrapper onClick={handleWeekSettingsApplyForWholeMonth}>
          <svg width="22px" height="22px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" fill="none">
            <path fill="#000000" fillRule="evenodd"
                  d="M4 2a2 2 0 00-2 2v9a2 2 0 002 2h2v2a2 2 0 002 2h9a2 2 0 002-2V8a2 2 0 00-2-2h-2V4a2 2 0 00-2-2H4zm9 4V4H4v9h2V8a2 2 0 012-2h5zM8 8h9v9H8V8z"/>
          </svg>
          <StyledTopPanelCopyText>
            <Content id={'consultations.planing.timetable.table.copy'}/>
          </StyledTopPanelCopyText>
        </StyledTopPanelCopyWrapper>
      </StyledTopPanel>
      <StyledTimetableWrapper>
        {thisWeek?.map(({ day, isAvailable, availability }) => (
          <AvailabilityTimeTableItem
            key={day.toISOString()}
            day={day}
            isAvailable={isAvailable}
            availability={availability}
            onUpdateDayConsultations={handleUpdateDayConsultations}
            onCopyForWholeWeek={copyForWholeWeek}
          />
        ))}
      </StyledTimetableWrapper>
    </StyledWrapper>
  );
};