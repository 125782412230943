import { TIMEZONES } from '@vaza-eu/shoelessly';
import { StyledInput, StyledOption, StyledOptionsWrapper, StyledPlaceholder, StyledWrapper } from './AuthInput.styled';
import { Content } from '../../../Content/Content';
import { TranslationTypesEnum } from '../../../../lib/types/translation-types-enum';
import React, { useMemo, useRef, useState } from 'react';
import { useRouter } from '../../../../lib/hooks/use-router';
import { useFormContext } from 'react-hook-form';
import { useOnClickOutside } from 'usehooks-ts';

type TimezoneSelectProps = {
  fieldName: string;
};

export const TimezoneSelect = ({ fieldName }: TimezoneSelectProps) => {
  const { locale } = useRouter();
  const { register, getValues, setValue, getFieldState } = useFormContext();
  const [isFocused, setIsFocused] = useState(false);
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const value = getValues(fieldName);
  const isError = !!getFieldState(fieldName)?.error;

  const selectRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(selectRef, () => setIsSelectOpen(false));

  const handleSelect = (value: typeof TIMEZONES[number]) => {
    setValue(fieldName, value.id);
    setSearchValue(`${value.city[locale]}, ${value.name[locale]} ${value.offsetUtc > 0 ? '+' : ''}${value.offsetUtc}`);
  };

  const sortedTimezones = useMemo(() => (
    [...TIMEZONES].sort((a, b) => {
      if (a.offsetUtc === b.offsetUtc) {
        return a.city[locale].localeCompare(b.city[locale]);
      }
      return a.offsetUtc - b.offsetUtc;
    })
  ), [locale]);

  const filteredTimezones = sortedTimezones.filter((timezone) => (
    timezone.name[locale].toLowerCase().includes(searchValue.toLowerCase()) ||
    timezone.city[locale].toLowerCase().includes(searchValue.toLowerCase()) ||
    timezone.offsetUtc.toString().includes(searchValue)
  ));

  const disableBottomBorderRadius = isSelectOpen || isFocused;

  return (
    <StyledWrapper
      ref={selectRef}
      {...{ isFocused, disableBottomBorderRadius, isError }}
      isFilled={!!value}
      onClick={() => setIsSelectOpen((prevState) => !prevState)}
    >
      {!isFocused && !value && (
        <StyledPlaceholder htmlFor={fieldName} hasStartIcon={false}>
          <Content
            id={'login.signInWithEmail.timezones.placeholder'}
            type={TranslationTypesEnum.Main}
          />
        </StyledPlaceholder>
      )}
      <StyledInput
        {...register(fieldName)}
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        id={fieldName}
        type={'text'}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
      />
      {(isSelectOpen || isFocused) && (
        <StyledOptionsWrapper>
          {filteredTimezones.map((timezone) => (
            <StyledOption
              key={timezone.city[locale]}
              onClick={() => handleSelect(timezone)}
            >
              {timezone.city[locale]}, {timezone.name[locale]} {timezone.offsetUtc > 0 ? '+' : ''}{timezone.offsetUtc}
            </StyledOption>
          ))}
        </StyledOptionsWrapper>
      )}
    </StyledWrapper>
  );
};