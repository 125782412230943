import React from 'react';
import {
  StyledDescription,
  StyledImageWrapper,
  StyledMainImage,
  StyledPhone,
  StyledTitle,
  StyledWrapper
} from './NoResults.styled';
import Image from 'next/image';
import { PhoneIcon } from '../../../../components/UI/SVG/PhoneIcon';
import { Content } from '../../../../components/Content/Content';

type NoResultsProps = {
  id: string;
};

export const NoResults = ({ id }: NoResultsProps) => {
  return (
    <StyledWrapper>
      <StyledImageWrapper>
        <StyledMainImage>
          <Image src={'/images/bird-in-chair.png'} alt={'Shoelessly'} fill/>
        </StyledMainImage>
        <StyledPhone>
          <PhoneIcon/>
        </StyledPhone>
      </StyledImageWrapper>
      <StyledTitle>
        <Content id={id + '.title'}/>
      </StyledTitle>
      <StyledDescription>
        <Content id={id + '.description'}/>
      </StyledDescription>
    </StyledWrapper>
  );
};