export const FacebookIcon = () => <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                       fill="none">
  <g clipPath="url(#clip0_2619_3633)">
    <path
      d="M12 0C5.3724 0 0 5.3724 0 12C0 18.6276 5.3724 24 12 24C18.6276 24 24 18.6276 24 12C24 5.3724 18.6276 0 12 0Z"
      fill="url(#paint0_linear_2619_3633)"/>
    <path
      d="M13.7281 16.8573H16.9846L17.4961 13.5492H13.7281V11.7409C13.7281 10.3668 14.1773 9.14813 15.4627 9.14813H17.5282V6.2615C17.1652 6.21243 16.3976 6.10547 14.9474 6.10547C11.9186 6.10547 10.1431 7.70481 10.1431 11.349V13.5498H7.0293V16.858H10.1424V25.9507C10.759 26.0426 11.3838 26.1055 12.0249 26.1055C12.6044 26.1055 13.17 26.0526 13.7281 25.9771V16.8573Z"
      fill="white"/>
  </g>
  <defs>
    <linearGradient id="paint0_linear_2619_3633" x1="3.5958" y1="3.5958" x2="21.969" y2="21.969"
                    gradientUnits="userSpaceOnUse">
      <stop stopColor="#2AA4F4"/>
      <stop offset="1" stopColor="#007AD9"/>
    </linearGradient>
    <clipPath id="clip0_2619_3633">
      <rect width="24" height="24" fill="white"/>
    </clipPath>
  </defs>
</svg>;
