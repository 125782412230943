import styled from 'styled-components';

export const StyledWrapper = styled.div`
  background-color: #ffffff;
  border-radius: 48px;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.05);
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const StyledTopPanel = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledTitle = styled.div`
  font-family: Futura Extra Black Condensed;
  font-size: 22px;
`;

export const StyledTopPanelCopyWrapper = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: black;
`;

export const StyledTopPanelCopyText = styled.div`
  font-family: 'Apercu Pro';
  font-size: 14px;
  text-align: left;
`;

export const StyledTimetableWrapper = styled.div`
  background-color: #ffffff;
  border-radius: 16px;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.05);
  padding: 16px;
`;
