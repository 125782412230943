import { useRouter as useOriginalRouter } from 'next/router';
import { Language, LANGUAGES } from '@vaza-eu/shoelessly';

export const useRouter = () => {
  const router = useOriginalRouter();

  const shallowReload = async () => {
    await router.replace(router.asPath, router.asPath, { scroll: false, shallow: true });
  };

  const clearPath = router.asPath.split('?')[0];

  return {
    ...router,
    locale: router.locale as Language,
    locales: LANGUAGES,
    shallowReload,
    clearPath,
  };
};