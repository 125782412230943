import styled from 'styled-components';
import { desktopMedia } from '@vaza-eu/vaza/dist/styles/devices';
import { Button } from '../../../../components/UI/Button/Button';

export const StyledWrapper = styled.div`
  display: flex;
  gap: 16px;
  margin: 20px 0;
  flex-direction: column;
`;

export const StyledFlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  
  @media (${desktopMedia}) {
    flex-direction: row;
  }
`;

export const StyledButtonsWrapper = styled.div`
  display: flex;
  gap: 16px;
  justify-content: end;
  margin-top: 20px;
`;

export const StyledButton = styled(Button)<{ isSave?: boolean }>`
  background-color: ${({ isSave }) => isSave ? '#e31337' : '#f1f1f1'};
  color: ${({ isSave }) => isSave ? '#ffffff' : '#000'};
  width: 176px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Futura Extra Black Condensed';
  font-size: 16px;
  border-radius: 17px;
`;