import {
  StyledMainItem,
  StyledMainWrapper,
  StyledTopDayAvailability,
  StyledTopDayName,
  StyledTopDayNumber,
  StyledTopWrapper,
  StyledWrapper
} from './AvailabilityTimeSlotsTableItem.styled';
import { Content } from '../../../../../../components/Content/Content';
import dayjs from 'dayjs';

type AvailabilityTimeSlotsTableItemProps = {
  isActive: boolean;
  availability: {
    day: dayjs.Dayjs;
    availability: {
      start: dayjs.Dayjs;
      end: dayjs.Dayjs;
    }[]
  };
}

export const AvailabilityTimeSlotsTableItem = ({ availability, isActive }: AvailabilityTimeSlotsTableItemProps) => {
  const slotsAvailable = availability.availability.length;

  return (
    <StyledWrapper>
      <StyledTopWrapper>
        <StyledTopDayName {...{ isActive }}>
          {availability.day.format('ddd')}
        </StyledTopDayName>
        <StyledTopDayNumber {...{ isActive }}>
          {availability.day.format('D')}
        </StyledTopDayNumber>
        <StyledTopDayAvailability slotsAvailable={!!slotsAvailable}>
          {slotsAvailable}
          <Content id={'consultations.planing.timeslots.table.availability'}/>
        </StyledTopDayAvailability>
      </StyledTopWrapper>
      <StyledMainWrapper>
        {availability.availability.map((time, index) => (
          <StyledMainItem key={index} isAvailable={true}>
            {time.start?.format('HH:mm A')}
          </StyledMainItem>
        ))}
      </StyledMainWrapper>
    </StyledWrapper>
  );
};