import {
  StyledMainItem,
  StyledMainWrapper,
  StyledTopDayAvailability,
  StyledTopDayName,
  StyledTopDayNumber,
  StyledTopWrapper,
  StyledWrapper
} from './AvailabilityTimeSlotsTableItem.styled';
import dayjs from 'dayjs';
import { Content } from '../../../../Content/Content';
import { TranslationTypesEnum } from '../../../../../lib/types/translation-types-enum';

type AvailabilityTimeSlotsTableItemProps = {
  isActive: boolean;
  availability: {
    day: dayjs.Dayjs;
    availability: {
      start: dayjs.Dayjs;
      end: dayjs.Dayjs;
    }[]
  };
  selectedTime: dayjs.Dayjs | null;
  setSelectedTime: (time: dayjs.Dayjs) => void;
}

export const AvailabilityTimeSlotsTableItem = ({
  availability,
  isActive,
  selectedTime,
  setSelectedTime
}: AvailabilityTimeSlotsTableItemProps) => {
  const slotsAvailable = availability.availability.length;

  return (
    <StyledWrapper>
      <StyledTopWrapper>
        <StyledTopDayName {...{ isActive }}>
          {availability.day.format('ddd')}
        </StyledTopDayName>
        <StyledTopDayNumber {...{ isActive }}>
          {availability.day.format('D')}
        </StyledTopDayNumber>
        <StyledTopDayAvailability slotsAvailable={!!slotsAvailable}>
          {slotsAvailable}
          <Content id={'consultations.planing.timeslots.table.availability'}
                   type={TranslationTypesEnum.BookAnAppointment}/>
        </StyledTopDayAvailability>
      </StyledTopWrapper>
      <StyledMainWrapper>
        {availability.availability.map((time, index) => (
          <StyledMainItem
            key={index}
            isAvailable={true}
            isSelected={!!selectedTime?.isSame(time.start)}
            onClick={() => setSelectedTime(time.start)}
          >
            {time.start?.format('HH:mm A')}
          </StyledMainItem>
        ))}
      </StyledMainWrapper>
    </StyledWrapper>
  );
};