import {
  StyledButtonsWrapper,
  StyledCancelButton,
  StyledEditorCustomButton,
  StyledEditorCustomButtonsWrapper,
  StyledEditorWrapper,
  StyledLanguagesSelect,
  StyledLanguagesSelectWrapper,
  StyledMobileEditorCheckBox,
  StyledMobileEditorCheckBoxLabel,
  StyledSaveButton,
  StyledTitle,
  StyledTopPanelWrapper,
  StyledWrapper,
} from './EditTranslationModal.styled';
import { Modal } from '../Modal/Modal';
import { TRANSLATION_LANGUAGES } from './EditTranslationModal.constants';
import { useEffect, useState } from 'react';
import { Editor } from './Editor/Editor';
import { useRouter } from '../../../lib/hooks/use-router';
import { useAppContext } from '../../../context/AppProvider/AppProvider';
import { useTranslationsQuery } from '../../../services/translations/use-translations-query';
import {
  useAllLanguagesTranslationsForTypeQuery
} from '../../../services/translations/use-all-languages-translations-for-type-query';
import { useTranslationsMutation } from '../../../services/translations/use-translations-mutation';
import { TranslationTypesEnum } from '../../../lib/types/translation-types-enum';
import Checkbox from '@mui/material/Checkbox';
import { Language, NOTIFICATION, SetTranslationRequest } from '@vaza-eu/shoelessly';
import { toast } from 'react-toastify';

type EditTranslationModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const DEFAULT_TRANSLATIONS = {
  sk: '',
  de: '',
  en: '',
  cs: '',
};

export const EditTranslationModal = ({ isOpen, onClose }: EditTranslationModalProps) => {
  const { translationProps: { key, type }, setTranslationProps } = useAppContext();
  const { locale } = useRouter();
  const [selectedLanguage, setSelectedLanguage] = useState<Language>((TRANSLATION_LANGUAGES.find(({ locale: translationLocale }) => locale === translationLocale)?.locale ?? 'sk') as Language);
  const [translations, setTranslations] = useState<SetTranslationRequest['translations']>(DEFAULT_TRANSLATIONS);
  const [translationsMobile, setTranslationsMobile] = useState<SetTranslationRequest['translationsMobile']>(
    DEFAULT_TRANSLATIONS);

  const { refetch, isFetching: translationsLoading } = useTranslationsQuery(type);
  const {
    data,
    isFetched,
    refetch: refetchSingleTranslations,
    isFetching: singleTranslationsLoading
  } = useAllLanguagesTranslationsForTypeQuery(type);
  const { mutateAsync, isLoading: saveIsLoading } = useTranslationsMutation();

  const mobileVersionKey = key + 'Mobile';

  useEffect(() => {
    if (data && isFetched && isOpen) {
      setTranslations({
        sk: data.sk[key] ?? '',
        de: data.de[key] ?? '',
        en: data.en[key] ?? '',
        cs: data.cs[key] ?? '',
      });
      setTranslationsMobile({
        sk: data.sk[mobileVersionKey] ?? '',
        de: data.de[mobileVersionKey] ?? '',
        en: data.en[mobileVersionKey] ?? '',
        cs: data.cs[mobileVersionKey] ?? '',
      });
    }
  }, [data, isFetched, type, key, isOpen]);

  useEffect(() => {
    const handleKeyDown = async (event: KeyboardEvent) => {
      if ((event.metaKey || event.ctrlKey) && event.key === 'Enter') {
        event.preventDefault();
        await handleSave();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [translations]);

  const handleSave = async () => {
    try {
      await mutateAsync({ translations, key, type, translationsMobile });
      await refetch();
      await refetchSingleTranslations();
    } catch (e) {
      toast(NOTIFICATION.ERROR[locale], { type: 'error' });
    } finally {
      handleClose();
    }
  };

  const handleSetEditorValue = (value: string) => {
    setTranslations({ ...translations, [selectedLanguage]: value });
  };

  const handleClose = () => {
    onClose();
    setTranslations(DEFAULT_TRANSLATIONS);
    setTranslationsMobile(DEFAULT_TRANSLATIONS);
    setTranslationProps({ key: '', type: TranslationTypesEnum.Main });
  };

  const handleToggleMobile = () => {
    setTranslationsMobile({
      ...translationsMobile,
      [selectedLanguage]: translationsMobile[selectedLanguage] ? '' : ' '
    });
  };

  const handleTransformCase = (
    value: string,
    transform: 'uppercase' | 'lowercase' | 'capitalize'
  ): string => {
    const transformText = (text: string): string => {
      switch (transform) {
        case 'uppercase':
          return text.toUpperCase();
        case 'lowercase':
          return text.toLowerCase();
        case 'capitalize':
          return text
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(' ');
        default:
          return text;
      }
    };

    return value.replace(/>([^<]+)</g, (match, p1) => {
      return `>${transformText(p1)}<`;
    });
  };

  return (
    <Modal
      {...{ isOpen, onClose: handleClose }}
      paperStyle={{
        maxWidth: '1000px',
        zIndex: 99999,
        width: '100%',
      }}
      style={{
        zIndex: 99999,
      }}
    >
      <StyledWrapper>
        <StyledTopPanelWrapper>
          <StyledTitle>
            Text Editor
          </StyledTitle>
          <StyledLanguagesSelectWrapper>
            {TRANSLATION_LANGUAGES.map(({ language, locale }) => (
              <StyledLanguagesSelect
                key={locale}
                isSelected={selectedLanguage === locale}
                onClick={() => setSelectedLanguage(locale as Language)}
              >
                {language}
              </StyledLanguagesSelect>
            ))}
          </StyledLanguagesSelectWrapper>
        </StyledTopPanelWrapper>
        <StyledEditorWrapper>
          <StyledEditorCustomButtonsWrapper>
            <StyledEditorCustomButton onClick={() => setTranslations({
              ...translations,
              [selectedLanguage]: handleTransformCase(translations[selectedLanguage], 'uppercase')
            })}>
              AA
            </StyledEditorCustomButton>
            <StyledEditorCustomButton onClick={() => setTranslations({
              ...translations,
              [selectedLanguage]: handleTransformCase(translations[selectedLanguage], 'capitalize')
            })}>
              Aa
            </StyledEditorCustomButton>
            <StyledEditorCustomButton onClick={() => setTranslations({
              ...translations,
              [selectedLanguage]: handleTransformCase(translations[selectedLanguage], 'lowercase')
            })}>
              aa
            </StyledEditorCustomButton>
          </StyledEditorCustomButtonsWrapper>
          {TRANSLATION_LANGUAGES.map(({ locale }) => (
            selectedLanguage === locale &&
            <Editor
              key={locale}
              value={translations[locale as Language]}
              setValue={handleSetEditorValue}
              disabled={translationsLoading}
            />
          ))}
        </StyledEditorWrapper>
        <StyledMobileEditorCheckBox onClick={handleToggleMobile}>
          <Checkbox checked={!!translationsMobile[selectedLanguage]}/>
          <StyledMobileEditorCheckBoxLabel>
            Text is different on mobile
          </StyledMobileEditorCheckBoxLabel>
        </StyledMobileEditorCheckBox>
        {!!translationsMobile[selectedLanguage] && TRANSLATION_LANGUAGES.map(({ locale }) => (
          selectedLanguage === locale &&
          <Editor
            key={locale}
            value={translationsMobile[locale as Language]}
            setValue={(value) => setTranslationsMobile({ ...translationsMobile, [locale]: value })}
            disabled={translationsLoading}
          />
        ))}
        <StyledButtonsWrapper>
          <StyledCancelButton
            onClick={onClose}
            {...{
              isLoading: saveIsLoading || translationsLoading || singleTranslationsLoading,
              isSelected: false
            }}>
            Cancel
          </StyledCancelButton>
          <StyledSaveButton onClick={handleSave} {...{
            isLoading: saveIsLoading || translationsLoading || singleTranslationsLoading,
            isSelected: false
          }}>
            Save
          </StyledSaveButton>
        </StyledButtonsWrapper>
      </StyledWrapper>
    </Modal>
  );
};
