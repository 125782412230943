import { StyledButton, StyledDescription, StyledTitle, StyledWrapper } from './ConsultationsPlaningSelectPage.styled';
import { Content } from '../../../../../components/Content/Content';
import { MY_CONSULTATIONS_PLANNING } from '../ConsultationsPlaning';


type ConsultationsPlaningSelectPageProps = {
  page: string;
  setPage: (page: string) => void;
}

export const ConsultationsPlaningSelectPage = ({ page, setPage }: ConsultationsPlaningSelectPageProps) => {

  return (
    <StyledWrapper>
      {MY_CONSULTATIONS_PLANNING.map((p) => (
        <StyledButton key={p} isActive={page === p} onClick={() => setPage(p)}>
          <Content id={'consultations.planing.' + p + '.selectButton'}/>
        </StyledButton>
      ))}
      {page === 'availability' && (
        <>
          <StyledTitle>
            <Content id={'consultations.planing.title'}/>
          </StyledTitle>
          <StyledDescription>
            <Content
              id={'consultations.planing.description'}
              replace={[
                {
                  pattern: '{plus-icon}',
                  replacement: `
                <svg width="12" height="12" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2.7 4.5C2.7 4.25147 2.90147 4.05 3.15 4.05H4.05V3.15C4.05 2.90147 4.25147 2.7 4.5 2.7C4.74853 2.7 4.95 2.90147 4.95 3.15V4.05H5.85C6.09853 4.05 6.3 4.25147 6.3 4.5C6.3 4.74853 6.09853 4.95 5.85 4.95H4.95V5.85C4.95 6.09853 4.74853 6.3 4.5 6.3C4.25147 6.3 4.05 6.09853 4.05 5.85V4.95H3.15C2.90147 4.95 2.7 4.74853 2.7 4.5Z" fill="black"/>
                   <path fill-rule="evenodd" clip-rule="evenodd" d="M0 4.5C0 2.01472 2.01472 0 4.5 0C6.98528 0 9 2.01472 9 4.5C9 6.98528 6.98528 9 4.5 9C2.01472 9 0 6.98528 0 4.5ZM4.5 0.9C2.51178 0.9 0.9 2.51178 0.9 4.5C0.9 6.48822 2.51178 8.1 4.5 8.1C6.48822 8.1 8.1 6.48822 8.1 4.5C8.1 2.51178 6.48822 0.9 4.5 0.9Z" fill="black"/>
                </svg>`
                },
                {
                  pattern: '{copy-icon}',
                  replacement: `<svg width="14px" height="14px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" fill="none">
  <path fill="#000000" fill-rule="evenodd" d="M4 2a2 2 0 00-2 2v9a2 2 0 002 2h2v2a2 2 0 002 2h9a2 2 0 002-2V8a2 2 0 00-2-2h-2V4a2 2 0 00-2-2H4zm9 4V4H4v9h2V8a2 2 0 012-2h5zM8 8h9v9H8V8z"/>
</svg>`
                }
              ]}
            />
          </StyledDescription>
        </>
      )}
    </StyledWrapper>
  );
};