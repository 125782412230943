import React, { forwardRef, ReactNode, useCallback } from 'react';
import { CircularProgress } from '@mui/material';
import { StyledContentWrapper, StyledLoadingWrapper, StyledStartIconWrapper, StyledTextWrapper } from './Button.styled';

type ButtonProps = {
  children: ReactNode;
  disabled?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  isLoading?: boolean;
  loadingSize?: number;
  startIcon?: ReactNode;
};

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({
    children,
    disabled,
    onClick,
    isLoading,
    loadingSize = 24.5,
    startIcon = null,
    ...rest
  }, ref) => {
    const handleClick = useCallback(
      (e: React.MouseEvent<HTMLButtonElement>) => {
        if (!disabled || isLoading) onClick?.(e);
      },
      [disabled, onClick, isLoading],
    );

    return (
      <StyledContentWrapper ref={ref} {...{ onClick: handleClick, disabled: disabled || isLoading }} {...rest}>
        {startIcon &&
          <StyledStartIconWrapper id={'button-icon'}>
            {startIcon}
          </StyledStartIconWrapper>
        }
        <StyledTextWrapper {...{ isLoading }} id={'button-content'}>
          {children}
        </StyledTextWrapper>
        <StyledLoadingWrapper {...{ isLoading }}>
          <CircularProgress color={'inherit'} size={loadingSize}/>
        </StyledLoadingWrapper>
      </StyledContentWrapper>
    );
  },
);

Button.displayName = 'Button';