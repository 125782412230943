import styled from 'styled-components';

export const StyledWrapper = styled.div`
  background-color: white;
  border-radius: 48px;
  width: 100%;
  padding: 20px 25px 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
`;

export const StyledImageWrapper = styled.div`
  position: relative;
  width: 140px;
  height: 140px;
  overflow: hidden;
  
  img {
    border-radius: 50%;
    object-fit: contain;
  }
`;

export const StyledTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-top: 20px;
`;

export const StyledTitleText = styled.span`
  font-family: Futura Extra Black Condensed;
  color: #e31337;
  font-size: 24px;
  padding-bottom: 4px;
  border-bottom: 1px solid #efefef;
`;

export const StyledFavoriteWrapper = styled.div`
  cursor: pointer;
  padding-right: 2px;
`;

export const StyledSpecialization = styled.div`
  font-family: Apercu Pro;
  display: flex;
  width: 100%;
  gap: 5px;
  font-size: 16px;
  text-align: left;
`;

export const StyledSpecializationName = styled.span`
  font-family: Futura Extra Black Condensed;
  text-transform: uppercase;
`;

export const StyledDescription = styled.div`
  font-family: Apercu Pro;
  padding-top: 5px;
  color: #414146;
  font-size: 14px;
`;

export const StyledSelectedTimeInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 20px 0 10px;
`;

export const StyledSelectedTimeInfoText = styled.div`
  font-family: Futura Extra Black Condensed;
  font-size: 20px;
  
  * {
    display: inline;
  }
`;

export const StyledSelectedTimeInfoTextComponent = styled.span`
  color: #e31337;
  padding: 0 4px;
  text-transform: uppercase;
`;

export const StyledSelectedTimeInfoConfirmation = styled.div`
  font-family: Apercu Pro;
`;