import styled from 'styled-components';
import { Button } from '../../../../../components/UI/Button/Button';

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 267px;
`;

export const StyledButton = styled(Button)<{ isActive: boolean }>`
  background-color: ${({ isActive }) => (isActive ? '#ffc546' : '#f3f3f3')};
  font-family: Futura Extra Black Condensed;
  font-size: 16px;
  height: 58px;
  color: black;
  border-radius: 16px;
`;

export const StyledTitle = styled.div`
  font-family: Futura Extra Black Condensed;
  font-size: 20px;
`;

export const StyledDescription = styled.div`
  font-family: 'Apercu Pro';
  font-size: 16px;
`;