import { css, CSSObject, DefaultTheme } from 'styled-components';

import { isFn } from '../helpers/is-fn';

type StyleFn<T extends Record<string, unknown> = Record<string, unknown>> = ({
  theme,
}: {
  theme: DefaultTheme;
} & T) => CSSObject | ReturnType<typeof css>;

export type StyledComponentProps<T extends Record<string, unknown> = Record<string, unknown>> =
  | StyleFn<T>
  | CSSObject
  | ReturnType<typeof css>;

export const getComponentStyle = <T extends Record<string, unknown> = Record<string, unknown>>(
  style: StyledComponentProps<T> | undefined,
  props: { theme: DefaultTheme } & T
): Omit<StyledComponentProps, 'ThemeFunc'> => {
  if (!style) return {};
  return isFn<StyleFn>(style) ? style(props) : style;
};
