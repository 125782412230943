import dayjs from 'dayjs';
import {
  StyledAvailabilityTimeTableTimeItem,
  StyledTimetableItemAction,
  StyledTimetableItemActionInfo,
  StyledTimetableItemLine
} from './AvailabilityTimeTableItem.styled';
import { TimePickerComponent } from './TimePickerComponent';
import Image from 'next/image';
import React from 'react';
import { Content } from '../../../../../../components/Content/Content';

type AvailabilityTimeTableTimeItemProps = {
  day: dayjs.Dayjs;
  index: number;
  isChecked: boolean;
  onUpdateItem: (index: number, start: dayjs.Dayjs | null, end: dayjs.Dayjs | null) => void;
  onAddNewTimeItem: () => void;
  onCopyForWholeWeek: () => void;
  onRemoveTimeItem: (index: number) => void;
  start: dayjs.Dayjs | null;
  end: dayjs.Dayjs | null;
}

export const AvailabilityTimeTableTimeItem = ({
  day,
  index,
  isChecked,
  onUpdateItem,
  onAddNewTimeItem,
  onCopyForWholeWeek,
  onRemoveTimeItem,
  start,
  end,
}: AvailabilityTimeTableTimeItemProps) => {

  const handleUpdateStart = (newValue: dayjs.Dayjs | null) => {
    onUpdateItem(index, newValue, end);
  };

  const handleUpdateEnd = (newValue: dayjs.Dayjs | null) => {
    onUpdateItem(index, start, newValue);
  };

  return (
    <StyledAvailabilityTimeTableTimeItem>
      <TimePickerComponent value={start} onUpdate={handleUpdateStart} day={day} type={'START'}/>
      <StyledTimetableItemLine/>
      <TimePickerComponent value={end} onUpdate={handleUpdateEnd} day={day} type={'END'}/>
      {index ? (
        <StyledTimetableItemAction onClick={() => onRemoveTimeItem(index)} isFirst {...{ isChecked }}>
          <Image src={'/images/icons/minus-icon.png'} alt={'Add new time'} width={20} height={20}/>
          <StyledTimetableItemActionInfo>
            <Content id={'consultations.planing.timetable.remove'}/>
          </StyledTimetableItemActionInfo>
        </StyledTimetableItemAction>
      ) : (
        <StyledTimetableItemAction onClick={onAddNewTimeItem} isFirst {...{ isChecked }}>
          <Image src={'/images/icons/plus-icon.png'} alt={'Add new time'} width={20} height={20}/>
          <StyledTimetableItemActionInfo>
            <Content id={'consultations.planing.timetable.add'}/>
          </StyledTimetableItemActionInfo>
        </StyledTimetableItemAction>
      )}
      {index ? (
        <StyledTimetableItemAction onClick={onAddNewTimeItem} {...{ isChecked }}>
          <Image src={'/images/icons/plus-icon.png'} alt={'Add new time'} width={20} height={20}/>
          <StyledTimetableItemActionInfo>
            <Content id={'consultations.planing.timetable.add'}/>
          </StyledTimetableItemActionInfo>
        </StyledTimetableItemAction>
      ) : (
        <StyledTimetableItemAction onClick={onCopyForWholeWeek} {...{ isChecked }}>
          <svg width="22px" height="22px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" fill="none">
            <path fill="#000000" fillRule="evenodd"
                  d="M4 2a2 2 0 00-2 2v9a2 2 0 002 2h2v2a2 2 0 002 2h9a2 2 0 002-2V8a2 2 0 00-2-2h-2V4a2 2 0 00-2-2H4zm9 4V4H4v9h2V8a2 2 0 012-2h5zM8 8h9v9H8V8z"/>
          </svg>
          <StyledTimetableItemActionInfo>
            <Content id={'consultations.planing.timetable.copy'}/>
          </StyledTimetableItemActionInfo>
        </StyledTimetableItemAction>
      )}
    </StyledAvailabilityTimeTableTimeItem>
  );
};
