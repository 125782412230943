import { useMutation, UseMutationResult, useQuery } from 'react-query';
import {
  GetDoctorAvailabilityResponse,
  GetDoctorAvailabilityTimeSlotsRequest,
  GetDoctorAvailabilityTimeSlotsResponse,
  SetDoctorAvailabilityRequest,
  SetDoctorAvailabilityResponse
} from '@vaza-eu/shoelessly';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../../../firebase';
import { dayjsWithOffset } from '../../lib/helpers/dayjs-with-offest';

export const useSetDoctorAvailabilityMutation = (): UseMutationResult<SetDoctorAvailabilityResponse, unknown, SetDoctorAvailabilityRequest> =>
  useMutation<SetDoctorAvailabilityResponse, unknown, SetDoctorAvailabilityRequest>(async (data: SetDoctorAvailabilityRequest) => {
    const cloudFn = httpsCallable<SetDoctorAvailabilityRequest, SetDoctorAvailabilityResponse>(functions,
      'setDoctorAvailability');
    const response = await cloudFn(data);
    return response.data;
  });

export const useCurrentDoctorAvailabilityQuery = () =>
  useQuery<GetDoctorAvailabilityResponse>(['current-doctor-availability'],
    async (): Promise<GetDoctorAvailabilityResponse> => {
      const cloudFn = httpsCallable(functions, 'getCurrentDoctorAvailability');
      const { data } = await cloudFn();
      return data as GetDoctorAvailabilityResponse;
    }
  );

export const useGetDoctorAvailabilityTimeSlotsQuery = (req: GetDoctorAvailabilityTimeSlotsRequest) =>
  useQuery(['doctor-availability-time-slots'],
    async () => {
      const cloudFn = httpsCallable(functions, 'getDoctorAvailabilityTimeSlots');
      const { data } = await cloudFn(req);

      const typedData = data as GetDoctorAvailabilityTimeSlotsResponse;

      return typedData.map((day) => ({
        day: dayjsWithOffset(day.day),
        availability: day.availability.map(({ start, end }) => ({
          start: dayjsWithOffset(start),
          end: dayjsWithOffset(end)
        }))
      }));
    },
    {
      enabled: !!req.doctorId
    }
  );