import styled from 'styled-components';

export const StyledWrapper = styled.div`
  width: 468px;
  margin: 0 auto;
`;

export const StyledTitle = styled.div`
  font-family: 'Futura Extra Black Condensed';
  font-size: 32px;
  padding-bottom: 10px;
`;

export const StyledDescription = styled.div`
  font-family: 'Apercu Pro';
  font-size: 20px;
  padding-bottom: 15px;
`;

export const StyledFieldsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  
  & > div {
    width: 468px;
  }
`;

export const StyledDivider = styled.div`
  position: relative;
  font-family: 'Apercu Pro';
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 70px 0 30px;
`;

export const StyledDividerLine = styled.div`
  background-color: #000;
  width: 140px;
  height: 1px;
`;

export const StyledMethodsButtonsWrapper = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
`;

export const StyledBottom = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  margin-top: 30px;
`;

export const StyledBottomText = styled.div`
  font-family: 'Apercu Pro';
  font-size: 14px;
`;

export const StyledBottomTextBold = styled.div`
  font-family: 'Futura Extra Black Condensed';
  font-size: 14px;
`;

export const StyledBottomImage = styled.div`
  
`;

export const StyledBottomMainImage = styled.div`
  position: relative;
  width: 400px;
  margin: 50px 0 0;
  height: 250px;
`;

export const StyledBottomSecondaryImage = styled.div`
  position: relative;
  width: 50px;
  margin: -250px -70px 150px 70px;
  height: 50px;
`;

export const StyledVerificationCodeInputsWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

export const StyledVerificationCodeInput = styled.input`
  font-family: 'Futura Extra Black Condensed';
  width: 82px;
  height: 61px;
  font-size: 24px;
  text-align: center;
  border: none;
  border-radius: 17px;
  background-color: white;
  color: black;
  
  &:focus {
    background-color: #ffe8b6;
  }
`;