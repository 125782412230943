import styled from 'styled-components';

export const StyledButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  position: fixed;
  bottom: 5px;
  right: 5px;
  z-index: 99999;
`;

export const StyledButton = styled.button`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #5050ff;
  cursor: pointer;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    margin-left: 2px;
    width: 25px;
    height: 25px;
    color: white;
  }
`;