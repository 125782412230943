import { destroyCookie, parseCookies, setCookie } from 'nookies';
import { GetServerSidePropsContext } from 'next';

export const ACCESS_TOKEN_COOKIE_KEY = 'AUTH_TOKEN_SHOELESSLY';

export const getCredentialsFromCookies = (ctx?: GetServerSidePropsContext) => {
  const cookies = parseCookies(ctx);
  return cookies[ACCESS_TOKEN_COOKIE_KEY] ?? null;
};

export const setCredentialsToCookies = (accessToken: string, ctx?: GetServerSidePropsContext) => {
  removeCredentialsFromCookies();
  setCookie(ctx, ACCESS_TOKEN_COOKIE_KEY, accessToken, { path: '/' });
};

export const removeCredentialsFromCookies = (ctx?: GetServerSidePropsContext) => {
  destroyCookie(ctx, ACCESS_TOKEN_COOKIE_KEY, { path: '/' });
};
