import styled from 'styled-components';

export const StyledWrapper = styled.div`

  .ql-editor {
    background-color: #eeeeee;
    color: black;
  }
`;

export const StyledToolbarInputsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
`;

export const StyledToolbarInputWrapper = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
  gap: 5px;
  margin-right: 10px;
`;

export const StyledToolbarInputIcon = styled.div`
  scale: 0.9;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledToolbarInput = styled.input`
  width: 140px;
  background-color: #eeeeee;
  border: none;
  color: black;
  padding: 5px 5px 5px 10px;
  border-radius: 5px;

  :focus {
    outline: none;
  }
`;