import styled, { css } from 'styled-components';
import { getComponentStyle, StyledComponentProps } from '../../lib/styles/get-component-style';

export const StyledContent = styled.div<{
  isEditing: boolean;
  contentStyle?: StyledComponentProps;
}>`
  display: inline-block;

  p {
    margin: 0;
  }

  ${({ isEditing }) =>
  isEditing &&
  css`
      min-width: 20px;
      min-height: 20px;
      outline: 2px solid red;
      cursor: pointer;
    `};

  ${({ contentStyle, theme }) => getComponentStyle(contentStyle, { theme })};
`;