import styled from 'styled-components';

export const StyledWrapper = styled.div`
  background-color: #ffffff;
  border-radius: 48px;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.05);
  padding: 30px 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-height: 800px;
  max-width: 820px;
  width: 820px;
  overflow-y: auto;
`;

export const StyledTopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledTitle = styled.div`
  font-family: Futura Extra Black Condensed;
  font-size: 22px;
`;

export const StyledArrows = styled.div`
  background-color: #f3f3f3;
  border-radius: 17px;
  display: flex;
`;

export const StyledArrowButton = styled.button<{ disabled: boolean }>`
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledTimetableWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
