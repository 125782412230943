import styled from 'styled-components';
import { Button } from '../../UI/Button/Button';
import { desktopMedia } from '@vaza-eu/vaza/dist/styles/devices';

export const StyledWrapper = styled.div`
  max-width: 98dvw;
  height: 95dvh;
  padding: 30px 30px 50px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow-y: auto;
  background-color: #f7e9e7;

  @media (${desktopMedia}) {
    width: 1215px;
    max-height: 95dvh;
    height: auto;
  }
`;

export const StyledTitle = styled.h1`
  font-family: Futura Extra Black Condensed;
  font-size: 48px;
  text-align: left;
`;

export const StyledMainWrapper = styled.div`
  display: grid;
  grid-template-columns: 315px auto;
  grid-gap: 20px;
`;

export const StyledLeftWrapper = styled.div`

`;

export const StyledRightWrapper = styled.div`

`;

export const StyledButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledCancelButton = styled(Button)`
  font-family: Futura Extra Black Condensed;
  font-size: 18px;
  background-color: #f3f3f3;
  width: 48%;
  border-radius: 50px;
  box-shadow: 4px 8px 24px 0 rgba(36, 107, 253, 0.25);
  padding: 15px 0;
  
  @media (${desktopMedia}) {
    width: 200px;
  }
`;

export const StyledContinueButton = styled(Button)`
  font-family: Futura Extra Black Condensed;
  font-size: 18px;
  background-color: #e31337;
  width: 48%;
  border-radius: 50px;
  box-shadow: 4px 8px 24px 0 rgba(36, 107, 253, 0.25);
  padding: 15px 0;
  
  :disabled {
    background-color: #d1d5db;
    color: white;
    cursor: not-allowed;
  }

  @media (${desktopMedia}) {
    width: 200px;
  }
`;