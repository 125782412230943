import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

let userOffset = 0;

export const setDayjsOffset = (offset: number) => {
  userOffset = offset;
};

export const dayjsWithOffset = (date?: string | number | Date | Dayjs): Dayjs => {
  return dayjs(date).utcOffset(userOffset);
};
