import {
  StyledButtonsWrapper,
  StyledCancelButton,
  StyledContinueButton,
  StyledLeftWrapper,
  StyledMainWrapper,
  StyledRightWrapper,
  StyledTitle,
  StyledWrapper
} from './BookAnAppointmentModal.styled';
import { Modal } from '../Modal/Modal';
import { Content } from '../../Content/Content';
import { TranslationTypesEnum } from '../../../lib/types/translation-types-enum';
import { BookAnAppointmentDoctorCard } from '../../UI/BookAnAppointmentDoctorCard/BookAnAppointmentDoctorCard';
import { useEffect, useState } from 'react';
import { AppointmentConfirmation } from './AppointmentConfirmation/AppointmentConfirmation';
import { AvailabilityTimeSlotsTable } from './AvailabilityTimeSlotsTable/AvailabilityTimeSlotsTable';
import dayjs from 'dayjs';
import { dayjsWithOffset } from '../../../lib/helpers/dayjs-with-offest';
import { BookAnAppointmentAuth } from './BookAnAppointmentAuth/BookAnAppointmentAuth';

type BookAnAppointmentModalProps = {
  isOpen: boolean;
  onClose: () => void;
}

export enum BookAnAppointmentSteps {
  INITIAL,
  CONFIRMATION,
  AUTH_INITIAL,
  AUTH_FILL_INFO,
  AUTH_CONFIRMATION_CODE,
  AUTH_LOGIN
}

export const BookAnAppointmentModal = ({ isOpen, onClose }: BookAnAppointmentModalProps) => {
  const [selectedDay, setSelectedDay] = useState<dayjs.Dayjs>(dayjsWithOffset());
  const [selectedTime, setSelectedTime] = useState<dayjs.Dayjs | null>(null);
  const [step, setStep] = useState<BookAnAppointmentSteps>(BookAnAppointmentSteps.AUTH_INITIAL);

  const handleContinue = () => {
    if (step === BookAnAppointmentSteps.INITIAL) {
      setStep(BookAnAppointmentSteps.AUTH_INITIAL);
    }

    if (step === BookAnAppointmentSteps.AUTH_INITIAL) {
      setStep(BookAnAppointmentSteps.AUTH_FILL_INFO);
    }

    if (step === BookAnAppointmentSteps.AUTH_FILL_INFO) {
      setStep(BookAnAppointmentSteps.AUTH_CONFIRMATION_CODE);
    }
  };

  const showAppointmentSelection = step === BookAnAppointmentSteps.INITIAL;
  const showAppointmentConfirmation = step === BookAnAppointmentSteps.CONFIRMATION;
  const showAuth = [
    BookAnAppointmentSteps.AUTH_INITIAL,
    BookAnAppointmentSteps.AUTH_FILL_INFO,
    BookAnAppointmentSteps.AUTH_CONFIRMATION_CODE,
    BookAnAppointmentSteps.AUTH_LOGIN
  ].includes(step);

  useEffect(() => {
    if (selectedTime) setSelectedDay(dayjsWithOffset(selectedTime));
  }, [selectedTime]);

  return (
    <Modal
      {...{ isOpen, onClose }}
      paperStyle={{
        overflow: 'hidden',
      }}
    >
      <StyledWrapper>
        <StyledTitle>
          <Content id={'modal.title'} type={TranslationTypesEnum.BookAnAppointment}/>
        </StyledTitle>
        <StyledMainWrapper>
          <StyledLeftWrapper>
            <BookAnAppointmentDoctorCard {...{ selectedTime }}/>
          </StyledLeftWrapper>
          <StyledRightWrapper>
            {showAppointmentSelection && (
              <AvailabilityTimeSlotsTable
                {...{ selectedDay, onSelectDay: setSelectedDay, selectedTime, setSelectedTime }}
              />
            )}
            {showAppointmentConfirmation && <AppointmentConfirmation/>}
            {showAuth && <BookAnAppointmentAuth {...{ step, setStep }}/>}
          </StyledRightWrapper>
        </StyledMainWrapper>
        <StyledButtonsWrapper>
          <StyledCancelButton>
            <Content id={'modal.cancel'} type={TranslationTypesEnum.BookAnAppointment}/>
          </StyledCancelButton>
          <StyledContinueButton onClick={handleContinue}>
            <Content id={'modal.confirm-and-continue'} type={TranslationTypesEnum.BookAnAppointment}/>
          </StyledContinueButton>
        </StyledButtonsWrapper>
      </StyledWrapper>
    </Modal>
  );
};