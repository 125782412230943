import {
  StyledCheckbox,
  StyledCheckboxUnchecked,
  StyledContentWrapper,
  StyledInnerContentWrapper,
  StyledText,
  StyledTextBold,
  StyledTitle,
  StyledWrapper
} from './AppointmentConfirmation.styled';
import { Content } from '../../../Content/Content';
import { TranslationTypesEnum } from '../../../../lib/types/translation-types-enum';
import { useState } from 'react';

export const AppointmentConfirmation = () => {
  const [isChecked, setIsChecked] = useState(false);

  return (
    <StyledWrapper>
      <StyledContentWrapper>
        <StyledTitle>
          <Content id={'appointmentConfirmation.clientInformation.title'}
                   type={TranslationTypesEnum.BookAnAppointment}/>
        </StyledTitle>
        <StyledInnerContentWrapper>
          <StyledText>
            Lukas Vyrva
          </StyledText>
          <StyledText>
            lukas.vyrva@gmail.com
          </StyledText>
        </StyledInnerContentWrapper>
      </StyledContentWrapper>
      <StyledContentWrapper>
        <StyledTitle>
          <Content id={'appointmentConfirmation.insurance.title'} type={TranslationTypesEnum.BookAnAppointment}/>
        </StyledTitle>
        <StyledInnerContentWrapper>
          <StyledTextBold>
            <Content id={'appointmentConfirmation.insurance.description'}
                     type={TranslationTypesEnum.BookAnAppointment}/>
          </StyledTextBold>
          <StyledText>
            <Content id={'appointmentConfirmation.insurance.text'} type={TranslationTypesEnum.BookAnAppointment}/>
          </StyledText>
        </StyledInnerContentWrapper>
      </StyledContentWrapper>
      <StyledContentWrapper style={{ flexDirection: 'row' }} onClick={() => setIsChecked(prevState => !prevState)}>
        <StyledCheckbox>
          {isChecked ? <CheckedIcon/> : <StyledCheckboxUnchecked/>}
        </StyledCheckbox>
        <StyledText>
          <Content id={'appointmentConfirmation.confirmation'} type={TranslationTypesEnum.BookAnAppointment}/>
        </StyledText>
      </StyledContentWrapper>
    </StyledWrapper>
  );
};

const CheckedIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="24" height="24" rx="8" fill="#E31337"/>
    <path d="M8 12L11 15L17 9" fill="#E31337"/>
    <path d="M8 12L11 15L17 9" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);