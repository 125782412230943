import styled, { css } from 'styled-components';
import { desktopMedia } from '@vaza-eu/vaza/dist/styles/devices';

export const StyledTimetableItem = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0;
  margin: 0 10px;
  border-bottom: 1px solid #ededed;

  &:last-child {
    border-bottom: none;
  }
`;

export const StyledTimetableItemLabelWrapper = styled.button`
  display: flex;
  gap: 10px;
  margin: 7px 30px auto 0;
`;

export const StyledTimetableItemCheckbox = styled.div`
  
  svg {
    border-radius: 4px;
    overflow: hidden;
  }
`;

export const StyledTimetableItemDayName = styled.div`
  font-family: Apercu Pro;
  font-size: 14px;
  width: 130px;
  cursor: pointer;
  color: #000;
  text-align: left;
`;

export const StyledAvailabilityTimeTableTimeItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

export const StyledAvailabilityTimeTableTimeItem = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledTimetableItemLine = styled.div`
  width: 12px;
  height: 2px;
  background-color: #000;
  border-radius: 1px;
  margin: 0 25px;
`;

export const StyledTimetableItemAction = styled.button<{
  isFirst?: boolean;
  isChecked?: boolean;
}>`
  position: relative;
  margin-left: 25px;
  opacity: 1;
  transition: opacity 0.1s;

  ${({ isFirst }) => isFirst && css`
    margin-left: 45px;
  `}

  ${({ isChecked }) => !isChecked && css`
    opacity: 0;
    pointer-events: none;
  `}

  &:hover label {
    @media (${desktopMedia}) {
      display: block;
    }
  }
`;

export const StyledTimetableItemActionInfo = styled.label`
  display: none;
  
  @media (${desktopMedia}) {
    font-family: Apercu Pro;
    display: none;
    position: absolute;
    top: calc(100% + 5px);
    left: 50%;
    background: #f3f3f3;
    border-radius: 0 8px 8px 8px;
    text-wrap: nowrap;
    padding: 5px 10px;
    color: black;
    z-index: 2;
  }
`;