import styled, { css } from 'styled-components';

export const StyledWrapper = styled.div`
  background-color: #ffffff;
  border-radius: 48px;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.05);
  padding: 50px;
  margin: 0 auto;
`;

export const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

export const StyledMonthSelector = styled.div`
  font-family: Futura Extra Black Condensed;
  font-weight: bold;
  font-size: 22px;
  text-transform: uppercase;
`;

export const StyledArrows = styled.div`
  background-color: #f3f3f3;
  border-radius: 17px;
  display: flex;
`;

export const StyledArrowButton = styled.button<{ disabled: boolean }>`
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledWeekdays = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-column-gap: 30px;
  font-weight: bold;
  margin-bottom: 15px;
  text-transform: uppercase;
  text-align: center;
`;

export const StyledWeekday = styled.div`
  text-align: center;
  font-family: Futura Extra Black Condensed;
  font-size: 22px;
  color: #919095;
`;

export const StyledDaysWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-column-gap: 30px;
  grid-row-gap: 10px;
  position: relative;
  z-index: 1;
`;

export const StyledCalendarDay = styled.div<{
  isFirst?: boolean;
  isLast?: boolean;
  selected?: boolean;
  allowedToSelect?: boolean;
}>`
  text-align: center;
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ allowedToSelect }) => !allowedToSelect && css`
    cursor: not-allowed;
  `};

  ${({ selected, isFirst, isLast, allowedToSelect }) => selected && css`
    ::before {
      content: '';
      display: block;
      background-color: #f2f2f2;
      width: 71px;
      height: 30px;
      position: absolute;
      z-index: 0;
      
      ${!allowedToSelect && css`
        cursor: not-allowed;
      `};

      ${isFirst && css`
        border-radius: 8px 0 0 8px;
      `};

      ${isLast && css`
        border-radius: 0 8px 8px 0;
      `};
  `};
  }
`;

export const StyledCalendarDayButton = styled.button<{
  empty?: boolean;
  selected?: boolean;
  isAnotherMonth?: boolean;
  allowedToSelect?: boolean;
}>`
  font-family: Futura Extra Black Condensed;
  font-size: 22px;
  position: relative;
  z-index: 10;
  background-color: #ececec;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: #4d4d4d;
  ${({ isAnotherMonth }) => isAnotherMonth && css`
    background-color: #fafafa;
    color: #d9d9d9;
  `};

  ${({ allowedToSelect }) => !allowedToSelect && css`
    cursor: not-allowed;
    pointer-events: none;
  `};

  ${({ selected, allowedToSelect }) => selected && css`
    background-color: #ffc546;
    color: black;
    
    ${!allowedToSelect && css`
      color: #4d4d4d;
    `};
  `};


  ${({ empty }) => empty && css`
    visibility: hidden;
  `};
`;
