import styled, { css } from 'styled-components';

export const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: column;
  width: calc(100% / 7);
  outline: 1px solid #ebebeb;
  margin-right: 1px;
`;

export const StyledTopWrapper = styled.div`
  padding: 40px 0 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

export const StyledTopDayName = styled.div<{ isActive: boolean }>`
  text-transform: uppercase;
  font-family: Futura Extra Black Condensed;
  font-size: 22px;
  color: #919095;
  transition: color 0.2s;
  
  ${({ isActive }) => isActive && css`
    color: #e31337;
  `}
`;

export const StyledTopDayNumber = styled.div<{ isActive: boolean }>`
  font-family: Futura Extra Black Condensed;
  font-size: 22px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ isActive }) => isActive && css`
    background-color: #ffc546;
  `}
`;

export const StyledTopDayAvailability = styled.div<{ slotsAvailable: boolean }>`
  display: flex;
  gap: 5px;
  font-family: Apercu Pro;
  font-size: 12px;
  color: #d9d9d9;
  
  ${({ slotsAvailable }) => slotsAvailable && css`
    color: #01a400;
  `}
`;

export const StyledMainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding-bottom: 20px;
`;

export const StyledMainItem = styled.button<{
  isAvailable: boolean,
  isSelected: boolean
}>`
  width: 84px;
  height: 32px;
  border-radius: 10px;
  font-family: Futura Extra Black Condensed;
  font-size: 14px;
  transition: background-color 0.2s, color 0.2s;
  background-color: #f3f3f3;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;

  ${({ isAvailable }) => isAvailable && css`
    background-color: #ffc546;
  `};

  ${({ isSelected }) => isSelected && css`
    background-color: #e31337;
    color: white;
  `};
`;