import {
  StyledTimezone,
  StyledTimezoneChange,
  StyledTimezoneWrapper,
  StyledTitle,
  StyledWrapper
} from './TimezoneSelect.styled';
import { Content } from '../../../../../components/Content/Content';
import { formatTimezone } from '../../../../../lib/helpers/format-timezone';
import { useRouter } from '../../../../../lib/hooks/use-router';
import { useAppContext } from '../../../../../context/AppProvider/AppProvider';

export const TimezoneSelect = () => {
  const { locale, push } = useRouter();
  const { user } = useAppContext();

  const handleRedirectToChangeTimezone = () => {
    push('/account/profile').catch(console.error);
  };

  return (
    <StyledWrapper>
      <StyledTitle>
        <Content id={'consultations.planing.timezone.title'}/>
      </StyledTitle>
      <StyledTimezoneWrapper>
        <StyledTimezone>
          {formatTimezone(user?.timezone, locale)}
        </StyledTimezone>
        <StyledTimezoneChange onClick={handleRedirectToChangeTimezone}>
          <Content id={'consultations.planing.timezone.change'}/>
        </StyledTimezoneChange>
      </StyledTimezoneWrapper>
    </StyledWrapper>
  );
};