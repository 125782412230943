import dayjs from 'dayjs';
import { useRouter } from '../../../../../../lib/hooks/use-router';
import { TimePicker } from '@mui/x-date-pickers';
import React from 'react';
import { Content } from '../../../../../../components/Content/Content';
import { useAppContext } from '../../../../../../context/AppProvider/AppProvider';
import { dayjsWithOffset } from '../../../../../../lib/helpers/dayjs-with-offest';

type TimePickerComponentProps = {
  day: dayjs.Dayjs;
  value: dayjs.Dayjs | null;
  onUpdate: (newValue: dayjs.Dayjs | null) => void;
  type: 'START' | 'END';
}

export const TimePickerComponent = ({ value, onUpdate, day, type }: TimePickerComponentProps) => {
  const { locale } = useRouter();
  const { isEditing } = useAppContext();

  const handleUpdate = (newValue: dayjs.Dayjs | null) => {
    newValue && onUpdate(dayjsWithOffset(day).set('hour', newValue!.hour()).set('minute', newValue!.minute()));
  };

  return (
    <TimePicker
      referenceDate={dayjsWithOffset(day).startOf('day')}
      ampm={locale === 'en'}
      ampmInClock={locale === 'en'}
      minutesStep={5}
      value={value}
      onChange={handleUpdate}
      className={'time-picker'}
      minTime={dayjsWithOffset(day).startOf('day')}
      maxTime={dayjsWithOffset(day).endOf('day')}
      label={
        <Content id={'consultations.planing.timetable.time.' + type}/>
      }
      slotProps={{
        popper: {
          sx: {
            '.MuiPaper-root': {
              border: 'none',
              borderRadius: '10px',
              marginTop: '10px',
              backgroundColor: '#f3f3f3',
            },
            '.MuiMenuItem-root': {
              fontFamily: 'Futura Extra Black Condensed',
              fontSize: '16px',
              borderRadius: '10px',
            },
            '.MuiList-root': {
              border: 'none',
            },
            '.MuiPickersLayout-contentWrapper': {
              border: 'none',
            },
            '.MuiButtonBase-root': {
              fontFamily: 'Futura Extra Black Condensed',
              fontSize: '16px',
              color: 'black',
            },
          },
        },
      }}
      sx={{
        '& .MuiOutlinedInput-root': {
          fontFamily: 'Futura Extra Black Condensed',
          fontSize: '16px',
          textAlign: 'center',
          borderRadius: '10px',
          backgroundColor: '#f3f3f3',
          border: 'none',
          color: 'black',
          width: locale === 'en' ? '130px' : '110px',
          height: '32px',

          '& fieldset': {
            border: 'none',
          },

          '&:hover fieldset': {
            border: 'none',
          },

          '&.Mui-focused fieldset': {
            border: 'none',
          },
        },
        '& .MuiInputLabel-root': {
          fontFamily: 'Futura Extra Black Condensed',
          fontSize: '14px',
          color: '#9ca3af',
          pointerEvents: isEditing ? 'visible' : 'none',
          top: -10,
        },
        '& .MuiInputLabel-shrink': {
          display: 'none',
        },
      }}
    />
  );
};