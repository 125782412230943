import type { AppProps } from 'next/app';
import { Hydrate, QueryClient, QueryClientProvider } from 'react-query';
import Head from 'next/head';
import Script from 'next/script';
import { ReactQueryDevtools } from 'react-query/devtools';
import { AppProvider } from '../context/AppProvider/AppProvider';
import { GlobalStyle } from '../lib/styles/globals';
import { QUERY_CLIENT_CONFIG } from '@vaza-eu/vaza';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useMemo } from 'react';

dayjs.extend(utc);

export default function App({ Component, pageProps }: AppProps) {
  const queryClient = useMemo(() => new QueryClient(QUERY_CLIENT_CONFIG), []);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools/>
        <Hydrate state={pageProps.dehydratedState}>
          <AppProvider translationType={pageProps.translationType}>
            <Head>
              <title>Shoelessly</title>
              <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1"/>
              <meta charSet="utf-8"/>
            </Head>
            <Script id="ff-fouc-fix" strategy={'beforeInteractive'} dangerouslySetInnerHTML={{ __html: 'var FF_FOUC_FIX;'}}/>
            <GlobalStyle/>
            <Component {...pageProps} />
            <ToastContainer/>
          </AppProvider>
        </Hydrate>
      </QueryClientProvider>
    </LocalizationProvider>
  );
}
