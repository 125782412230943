import styled from 'styled-components';

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 60px 0;
`;

export const StyledTitle = styled.div`
  font-family: 'Futura Extra Black Condensed';
  font-size: 20px;
  padding-top: 50px;
`;

export const StyledDescription = styled.div`
  font-family: 'Apercu Pro';
  font-size: 16px;
  padding-top: 10px;
  max-width: 380px;
  text-align: center;
`;

export const StyledImageWrapper = styled.div`
  position: relative;
`;

export const StyledMainImage = styled.div`
  position: relative;
  height: 220px;
  width: 220px;
`;

export const StyledPhone = styled.div`
  position: absolute;
  width: 40px;
  height: 40px;
  top: 15px;
  left: 10px;
`;