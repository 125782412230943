import { StyledButton, StyledButtonsWrapper, StyledFlexWrapper, StyledWrapper } from './ConsultationsPlaning.styled';
import { ConsultationsPlaningSelectPage } from './ConsultationsPlaningSelectPage/ConsultationsPlaningSelectPage';
import { ConsultationsPlanningCalendar } from './ConsultationsPlaningCalendar/ConsultationsPlanningCalendar';
import { TimezoneSelect } from './TimezoneSelect/TimezoneSelect';
import { AvailabilityTimeTable } from './AvailabilityTimeTable/AvailabilityTimeTable';
import React, { useEffect, useMemo, useState } from 'react';
import { Content } from '../../../../components/Content/Content';
import {
  useCurrentDoctorAvailabilityQuery,
  useGetDoctorAvailabilityTimeSlotsQuery,
  useSetDoctorAvailabilityMutation
} from '../../../../services/consultations/common';
import dayjs from 'dayjs';
import { AvailabilityTimeSlotsTable } from './AvailabilityTimeSlotsTable/AvailabilityTimeSlotsTable';
import { getAuth } from 'firebase/auth';
import { Loading } from '../../../../components/UI/Loading/Loading';
import { dayjsWithOffset } from '../../../../lib/helpers/dayjs-with-offest';
import { toast } from 'react-toastify';
import { useRouter } from '../../../../lib/hooks/use-router';
import { ConsultationReports } from './ConsultationReports/ConsultationReports';
import { NOTIFICATION } from '@vaza-eu/shoelessly';

export type AvailabilitiesState = {
  day: dayjs.Dayjs,
  isAvailable: boolean,
  availability: {
    start: dayjs.Dayjs | null,
    end: dayjs.Dayjs | null,
  }[]
}

export const AVAILABLE_FUTURE_DAYS = 180;
export const MY_CONSULTATIONS_PLANNING = ['availability', 'calendar', 'reports'];


export const ConsultationsPlaning = () => {
  const { locale } = useRouter();
  const { data, isFetched, isLoading: isLoadingAvailabilities } = useCurrentDoctorAvailabilityQuery();
  const { mutateAsync, isLoading } = useSetDoctorAvailabilityMutation();

  const auth = getAuth();
  const { refetch: refetchTimeSlots } = useGetDoctorAvailabilityTimeSlotsQuery({ doctorId: auth.currentUser?.uid ?? '' });

  const initDays: AvailabilitiesState[] = useMemo(() =>
      Array.from({ length: AVAILABLE_FUTURE_DAYS }, (_, i) => ({
        day: dayjsWithOffset().add(i, 'day').startOf('day'),
        isAvailable: false,
        availability: [{
          start: null,
          end: null,
        }]
      }))
    , []);

  const [page, setPage] = useState('availability');
  const [selectedDay, setSelectedDay] = useState<dayjs.Dayjs>(dayjsWithOffset());
  const [availability, setAvailability] = useState<AvailabilitiesState[]>(initDays);

  useEffect(() => {
    const newData = [...initDays];

    data?.forEach(({ day, isAvailable, availability }) => {
      const index = newData.findIndex((item) => item.day.isSame(day, 'day'));
      if (index === -1) return;

      newData[index] = {
        day: dayjsWithOffset(day),
        isAvailable,
        availability: availability?.map(({ start, end }) => ({
          start: dayjsWithOffset(start),
          end: dayjsWithOffset(end)
        }))
      };
    });

    setAvailability(newData);
  }, [data]);


  const handleSave = async () => {
    try {
      if (!isFetched) return;

      const data = availability
        .map(({ availability, day, isAvailable }) => ({
            day: day.toISOString(),
            isAvailable,
            availability: availability
              .filter(({ start, end }) => start && end)
              .map(({ start, end }) => ({
                start: start!.startOf('minute').toISOString(),
                end: end!.startOf('minute').toISOString()
              }))
          })
        )
        .filter(({ availability }) => availability.length);

      await mutateAsync({
        availability: data
      });

      toast(NOTIFICATION.SAVED[locale], { type: 'success' });
      await refetchTimeSlots();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <StyledWrapper>
      <StyledFlexWrapper>
        <ConsultationsPlaningSelectPage {...{ page, setPage }}/>
        {(page === 'calendar' || page === 'availability') && (
          <ConsultationsPlanningCalendar onSelectDay={setSelectedDay} selectedDay={selectedDay}/>
        )}
      </StyledFlexWrapper>
      {page === 'availability' && (
        isLoadingAvailabilities ? (
          <Loading
            size={80}
            center
            margin={'50px auto'}
          />
        ) : (
          <>
            <TimezoneSelect/>
            <AvailabilityTimeTable {...{ availability, setAvailability, selectedDay }}/>
            <StyledButtonsWrapper>
              <StyledButton>
                <Content id={'consultations.planing.cancel'}/>
              </StyledButton>
              <StyledButton isSave onClick={handleSave} isLoading={isLoading}>
                <Content id={'consultations.planing.save'}/>
              </StyledButton>
            </StyledButtonsWrapper>
          </>
        )
      )}
      {page === 'calendar' && (
        <AvailabilityTimeSlotsTable
          selectedDay={selectedDay}
          onSelectDay={setSelectedDay}
        />
      )}
      {page === 'reports' && (
        <ConsultationReports/>
      )}
    </StyledWrapper>
  );
};