import {
  StyledAvailabilityTimeTableTimeItems,
  StyledTimetableItem,
  StyledTimetableItemCheckbox,
  StyledTimetableItemDayName,
  StyledTimetableItemLabelWrapper
} from './AvailabilityTimeTableItem.styled';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { useRouter } from '../../../../../../lib/hooks/use-router';
import { CheckedIcon } from '../../../../../../components/UI/SVG/CheckedIcon';
import { UncheckedIcon } from '../../../../../../components/UI/SVG/UncheckedIcon';
import { AvailabilityTimeTableTimeItem } from './AvailabilityTimeTableTimeItem';
import { AvailabilitiesState } from '../../ConsultationsPlaning';
import { upperFirst } from 'tiny-case';

type AvailabilityTimeTableItemProps = {
  day: dayjs.Dayjs;
  isAvailable: boolean;
  availability: AvailabilitiesState['availability'];
  onUpdateDayConsultations: (day: dayjs.Dayjs,
    isAvailable: boolean,
    consultations: AvailabilitiesState['availability']) => void;
  onCopyForWholeWeek: (day: dayjs.Dayjs, isAvailable: boolean) => void;
}

export const AvailabilityTimeTableItem = ({
  day,
  isAvailable,
  availability,
  onUpdateDayConsultations,
  onCopyForWholeWeek
}: AvailabilityTimeTableItemProps) => {
  const { locale } = useRouter();
  const [isChecked, setIsChecked] = useState(isAvailable);
  const [checkedOnValid, setCheckedOnValid] = useState(false);

  const updateItem = (index: number, start: dayjs.Dayjs | null, end: dayjs.Dayjs | null) => {
    onUpdateDayConsultations(day, isChecked, availability.map((item, i) => i === index ? { start, end } : item));
    if (!checkedOnValid && start && end) {
      setIsChecked(true);
      setCheckedOnValid(true);
    }
  };

  const addNewTimeItem = () => {
    onUpdateDayConsultations(day, isChecked, [...availability, { start: null, end: null }]);
  };

  const removeTimeItem = (index: number) => {
    onUpdateDayConsultations(day, isChecked, availability.filter((_, i) => i !== index));
  };

  const formattedDay = () => {
    if (locale === 'en') return upperFirst(day.locale(locale).format('DD MMM dddd'));
    return upperFirst(day.locale(locale).format('DD. MM. dddd'));
  };

  const handleCopyForWholeWeek = () => {
    onCopyForWholeWeek(day, isChecked);
  };

  return (
    <StyledTimetableItem>
      <StyledTimetableItemLabelWrapper onClick={() => setIsChecked(prevState => !prevState)}>
        <StyledTimetableItemCheckbox>
          {isChecked ? <CheckedIcon/> : <UncheckedIcon/>}
        </StyledTimetableItemCheckbox>
        <StyledTimetableItemDayName>
          {formattedDay()}
        </StyledTimetableItemDayName>
      </StyledTimetableItemLabelWrapper>
      <StyledAvailabilityTimeTableTimeItems>
        {availability?.map(({ start, end }, i) => (
          <AvailabilityTimeTableTimeItem
            key={i}
            index={i}
            {...{ start, end, day }}
            isChecked={isChecked}
            onUpdateItem={updateItem}
            onAddNewTimeItem={addNewTimeItem}
            onCopyForWholeWeek={handleCopyForWholeWeek}
            onRemoveTimeItem={removeTimeItem}
          />
        ))}
      </StyledAvailabilityTimeTableTimeItems>
    </StyledTimetableItem>
  );
};


